import { useState } from "react";

import { checkObjectsAreEqual } from "../../../utils/objectsEqualityChecker";

const useItemDeleteModalHook = (chosenItem: any) => {
  const [formInputValid, setFormInputValid] = useState<boolean>(false);
  
  const validateInput = (inputData: string) => {
    const inputValid = checkObjectsAreEqual(inputData, chosenItem.name);
    setFormInputValid(inputValid);
  };

  return {
    formInputValid,
    setFormInputValid,
    validateInput,
  };
};

export default useItemDeleteModalHook;
