export class ClickableItemMetadata {
    id: number;
    title: string;
    description?: string;
    path: string;

  constructor(id: number, title: string, path: string, description?: string) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.path = path;
  }
}