import axios from 'axios';
import { SystemCompleteDto, SystemDto } from '../models/dtos/system/SystemDtos';
import { ClassCompleteDto, ClassDto } from '../models/dtos/class/ClassDtos';
import { SystemAssociatedClassDto } from '../models/dtos/system/SystemAssociatedClassDto';
import { RelationTypeCompleteDto, RelationTypeDto } from '../models/dtos/RelationTypeDtos';
import { SystemAssociatedRelationTypeDto } from '../models/dtos/system/SystemAssociatedRelationTypeDto';
import { SystemAssociatedProfileDto } from '../models/dtos/system/SystemAssociatedProfileDto';
import { FeatureCompleteDto } from '../models/dtos/FeatureDtos';
import { SystemAssociatedFeatureDto } from '../models/dtos/system/SystemAssociatedFeatureDto';
import { ClassAssociatedFeatureDto } from '../models/dtos/class/ClassAssociatedFeatureDto';
import { ProfileComposition, ProfilePartDto } from '../models/dtos/profile/ProfileComposition';
import { ProfileCompleteDto, ProfileDto } from '../models/dtos/profile/ProfileDtos';
import { UnitOfMeasurementCompleteDto } from '../models/dtos/UnitOfMeasurementDtos';
import { AttributeCompleteDto, AttributeDto } from '../models/dtos/AttributeDtos';

const axiosInstance = axios.create();

//@ts-ignore
axiosInstance.defaults.baseURL = window.env.BACKEND_URL;

const apiVersion = '0.1';

export { axiosInstance };

const systems = {

    // Systems
    getAll: () => axiosInstance.get<SystemCompleteDto[]>(
        '/systems', 
        { 
            headers: { 'x-api-version': apiVersion } 
        }
    ),
    getById: (id: number) => axiosInstance.get<SystemCompleteDto>(`systems/${id}`, {
        headers: {
            'x-api-version': apiVersion
        }
    }),
    create: (data: SystemDto) => axiosInstance.post<SystemCompleteDto>(
        '/systems', 
        data, 
        { 
            headers: { 'x-api-version': apiVersion } 
        }
    ),
    update: (id: number, data: SystemDto) => axiosInstance.put<SystemCompleteDto>(
        `/systems/${id}`, 
        data, 
        { 
            headers: { 'x-api-version': apiVersion }
        }
    ),
    delete: (id: number) => axiosInstance.delete(`systems/${id}`, {
        headers: {
            'x-api-version': apiVersion
        }
    }),

    // System classes
    getClasses: (id: number) => axiosInstance.get<ClassCompleteDto[]>(`systems/${id}/classes`, {
        headers: {
            'x-api-version': apiVersion
        }
    }),
    addClass: (id: number, data: SystemAssociatedClassDto) => axiosInstance.post<ClassCompleteDto>(`systems/${id}/classes`, data, {
        headers: {
            'x-api-version': apiVersion
        }
    }),
    removeClass: (id: number, classId: number) => axiosInstance.delete(`systems/${id}/classes/${classId}`, {
        headers: {
            'x-api-version': apiVersion
        }
    }),

    // System relation types
    getRelationTypes: (id: number) => axiosInstance.get<RelationTypeCompleteDto[]>(`systems/${id}/relation-types`, {
        headers: {
            'x-api-version': apiVersion
        }
    }),
    addRelationType: (id: number, data: SystemAssociatedRelationTypeDto) => axiosInstance.post<RelationTypeCompleteDto>(`systems/${id}/relation-types`, data, {
        headers: {
            'x-api-version': apiVersion
        }
    }),
    removeRelationType: (id: number, relationTypeId: number) => axiosInstance.delete(`systems/${id}/relation-types/${relationTypeId}`, {
        headers: {
            'x-api-version': apiVersion
        }
    }),

    // System profiles
    getProfileCompositions: (id: number) => axiosInstance.get<ProfilePartDto[]>(`systems/${id}/profiles`, {
        headers: {
            'x-api-version': apiVersion
        }
    }),
    addProfileCompositions: (id: number, data: SystemAssociatedProfileDto[]) => axiosInstance.post<ProfilePartDto[]>(`systems/${id}/profiles`, data, {
        headers: {
            'x-api-version': apiVersion
        }
    }),

    // System features
    getFeatures: (id: number) => axiosInstance.get<FeatureCompleteDto[]>(`systems/${id}/features`, {
        headers: {
            'x-api-version': apiVersion
        }
    }),
    addFeature: (id: number, data: SystemAssociatedFeatureDto) => axiosInstance.post<FeatureCompleteDto>(`systems/${id}/features`, data, {
        headers: {
            'x-api-version': apiVersion
        }
    }),
    removeFeature: (id: number, featureId: number) => axiosInstance.delete(`systems/${id}/features/${featureId}`, {
        headers: {
            'x-api-version': apiVersion
        }
    }),
}

const classes = {

    // Classes
    getAll: () => axiosInstance.get<ClassCompleteDto[]>(
        '/classes', 
        { 
            headers: { 'x-api-version': apiVersion } 
        }
    ),
    getById: (id: number) => axiosInstance.get<ClassCompleteDto>(
        `/classes/${id}`, 
        { 
            headers: { 'x-api-version': apiVersion }
        }
    ),
    create: (data: ClassDto) => axiosInstance.post<ClassCompleteDto>(
        '/classes', 
        data, 
        { 
            headers: { 'x-api-version': apiVersion } 
        }
    ),
    update: (id: number, data: ClassDto) => axiosInstance.put<ClassCompleteDto>(
        `/classes/${id}`, 
        data, 
        { 
            headers: { 'x-api-version': apiVersion }
        }
    ),
    delete: (id: number) => axiosInstance.delete(`classes/${id}`, {
        headers: {
            'x-api-version': apiVersion
        }
    }),

    // Class features
    getFeatures: (id: number) => axiosInstance.get<FeatureCompleteDto[]>(`classes/${id}/features`, {
        headers: {
            'x-api-version': apiVersion
        }
    }),
    addFeature: (id: number, data: ClassAssociatedFeatureDto) => axiosInstance.post<FeatureCompleteDto>(`classes/${id}/features`, data, {
        headers: {
            'x-api-version': apiVersion
        }
    }),
    removeFeature: (id: number, featureId: number) => axiosInstance.delete(`classes/${id}/features/${featureId}`, {
        headers: {
            'x-api-version': apiVersion
        }
    }),
}

const relationTypes = {
    // Relation types
    getAll: () => axiosInstance.get<RelationTypeCompleteDto[]>(
        '/relation-types', 
        { 
            headers: { 'x-api-version': apiVersion } 
        }
    ),
    getById: (id: number) => axiosInstance.get<RelationTypeCompleteDto>(
        `/relation-types/${id}`, 
        { 
            headers: { 'x-api-version': apiVersion }
        }
    ),
    create: (data: RelationTypeDto) => axiosInstance.post<RelationTypeCompleteDto>(
        '/relation-types', 
        data, 
        { 
            headers: { 'x-api-version': apiVersion } 
        }
    ),
    update: (id: number, data: RelationTypeDto) => axiosInstance.put<RelationTypeCompleteDto>(
        `/relation-types/${id}`, 
        data, 
        { 
            headers: { 'x-api-version': apiVersion }
        }
    ),
    delete: (id: number) => axiosInstance.delete(`relation-types/${id}`, {
        headers: {
            'x-api-version': apiVersion
        }
    }),
}

const profiles = {

    // Profiles
    getAll: () => axiosInstance.get<ProfileCompleteDto[]>(
        '/profiles', 
        { 
            headers: { 'x-api-version': apiVersion } 
        }
    ),
    getById: (id: number) => axiosInstance.get<ProfileCompleteDto>(
        `/profiles/${id}`, 
        { 
            headers: { 'x-api-version': apiVersion }
        }
    ),
    create: (data: ProfileDto) => axiosInstance.post<ProfileCompleteDto>(
        '/profiles', 
        data, 
        { 
            headers: { 'x-api-version': apiVersion } 
        }
    ),
    update: (id: number, data: ProfileDto) => axiosInstance.put<ProfileCompleteDto>(
        `/profiles/${id}`, 
        data, 
        { 
            headers: { 'x-api-version': apiVersion }
        }
    ),
    delete: (id: number) => axiosInstance.delete(`profiles/${id}`, {
        headers: {
            'x-api-version': apiVersion
        }
    }),

    // Profile compositions
    getCompositions: (id: number) => axiosInstance.get<ProfilePartDto[]>(`profiles/${id}/sub-profiles`, {
        headers: {
            'x-api-version': apiVersion
        }
    }),
    addCompositions: (id: number, data: ProfileComposition[]) => axiosInstance.post<ProfilePartDto[]>(`profiles/${id}/sub-profiles`, data, {
        headers: {
            'x-api-version': apiVersion
        }
    }),

    // Profile attributes
    getAllAttributes: (profileId: number) => axiosInstance.get<AttributeCompleteDto[]>(`profiles/${profileId}/features/attributes`, {
        headers: {
            'x-api-version': apiVersion
        }
    }),
    getAttributeById: (profileId: number, attributeId: number) => axiosInstance.get<AttributeCompleteDto>(`profiles/${profileId}/features/attributes/${attributeId}`, {
        headers: {
            'x-api-version': apiVersion
        }
    }),
    createAttribute: (profileId: number, data: AttributeDto) => axiosInstance.post<AttributeCompleteDto>(`profiles/${profileId}/features/attributes`, data, {
        headers: {
            'x-api-version': apiVersion
        }
    }),
    updateAttribute: (profileId: number, attributeId: number, data: AttributeDto) => axiosInstance.put<AttributeCompleteDto>(`profiles/${profileId}/features/attributes/${attributeId}`, data, {
        headers: {
            'x-api-version': apiVersion
        }
    }),
    deleteAttribute: (profileId: number, attributeId: number) => axiosInstance.delete(`profiles/${profileId}/features/attributes/${attributeId}`, {
        headers: {
            'x-api-version': apiVersion
        }
    }),
}

const features = {
    getAll: () => axiosInstance.get<FeatureCompleteDto[]>(
        '/features', 
        { 
            headers: { 'x-api-version': apiVersion } 
        }
    ),
}

const unitsOfMeasurement = {
    getAll: () => axiosInstance.get<UnitOfMeasurementCompleteDto[]>(
        '/uom', 
        { 
            headers: { 'x-api-version': apiVersion } 
        }
    ),
    getById: (id: number) => axiosInstance.get<UnitOfMeasurementCompleteDto>(
        `/uom/${id}`, 
        { 
            headers: { 'x-api-version': apiVersion }
        }
    ),
}

const services = { systems, classes, relationTypes, profiles, features, unitsOfMeasurement };

export default services;