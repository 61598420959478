export class TransferData {
    key: string;
    title: string;
    description?: string;
    disabled: boolean;

    constructor(key: string, title: string, description?: string, disabled: boolean = false) {
        this.key = key;
        this.title = title;
        this.description = description;
        this.disabled = disabled;
    }
};