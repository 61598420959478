import React from "react";

import ThemeProvider from "@plasma/ui.themes.theme-provider";
import { ThemeOverride } from "@plasma/ui.utils.types";
import Form from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import TextInput from "@plasma/ui.input.text-input";
import TextArea from "@plasma/ui.input.text-area";
import Select from "@plasma/ui.input.select";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import Switch from "@plasma/ui.input.switch";
import { useParams } from '@plasma/ui.application.router';

import themes from "../../../../themes";
import Breadcrumbs from "../../../../components/shared/Breadcrumbs/Breadcrumbs";
import { MainContainer } from "./create-attribute.styles";
import useCreateAttributeHook from "./create-attribute.hook";
import FormButtons from "../../../../components/shared/FormButtons/FormButtons";
import { useStore } from "../../../../stores/store";
import SmsSpin from "../../../../components/core/SmsSpin/SmsSpin";
import { AttributeDto } from "../../../../models/dtos/AttributeDtos";

function CreateAttribute(props: Readonly<ThemeOverride>) {
    const { size, themeMode } = props;
    const { t } = useTranslation();
    const { profileId } = useParams();
    const logic = useCreateAttributeHook(Number(profileId));
    const { ProfileStore } = useStore();

    return (
        <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
            <MainContainer>
                {ProfileStore.attributesAreLoading || !ProfileStore.chosenProfile ? <SmsSpin/> : 
                    <>
                        <Breadcrumbs 
                            newCurrentPath={`/profiles/${profileId}/attributes/create`}
                            chosenProfile={ProfileStore.chosenProfile?.name}
                            chosenProfileId={Number(profileId)}
                        />
                        <h1 className="header"><strong>{t("attributes.create.title")}</strong></h1>
                        <Form 
                            className="form"
                            onChange={(data: Partial<AttributeDto>) => logic.setAttributeToCreate(data as AttributeDto)}
                        >
                            <FormField required label={t("form.name")} name="name">
                                <TextInput required/>
                            </FormField>
                            <FormField label={t("form.description")} name="description">
                                <TextArea rows={5}/>
                            </FormField>
                            <FormField required label={t("form.data-type")} name="type">
                                <Select options={logic.typeSelectOptions}/>
                            </FormField>
                            <FormField required label={t("form.source")} name="source">
                                <Select options={logic.sourceSelectOptions}/>
                            </FormField>
                            <FormField required label={t("form.uom")} name="unitId">
                                <Select options={logic.uomSelectOptions}/>
                            </FormField>
                            <FormField required label={t("form.is-required")} name="mandatory">
                                <Switch />
                            </FormField>
                        </Form>
                        <FormButtons 
                            objectValid={logic.objectValid} 
                            handleOnSave={logic.handleCreateAttribute}
                            itemToSave={logic.attributeToCreate}
                            handleOnCancel={logic.handleNavigate}
                            onCancelParam={`/profiles/${profileId}?tabKey=3`}
                        />
                    </>
                }
            </MainContainer>
        </ThemeProvider>
    );
}

export default observer(CreateAttribute);