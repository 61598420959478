import React from 'react';

import { Route } from '@plasma/ui.application.router';

const Classes = React.lazy(() => import('.'));

export const ClassesRoutes = [
  new Route({
    path: '/classes',
    element: Classes,
  }),
];
