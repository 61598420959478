import React, { useEffect } from "react";

import { Trans, useTranslation } from "react-i18next";
import { notification as notificationant } from "antd";
import { useNavigate } from "@plasma/ui.application.router";

import { useStore } from "../../stores/store";
import { ClickableItemMetadata } from "../../components/shared/ClickableItem/ClickableItem.types";

const useRelationTypesHook = () => {
    const navigate = useNavigate();
    const { RelationTypeStore } = useStore();
    const { t } = useTranslation();

    const generateClickableRelationTypeItems = () => {
        const clickableRelationTypeItems: ClickableItemMetadata[] = [];
        RelationTypeStore.relationTypes.map((item) => {
            clickableRelationTypeItems.push(new ClickableItemMetadata(item.id, item.name, `/relation-types/${item.id}`, item.description));
        });
        return clickableRelationTypeItems;
    }

    useEffect(() => {
        if (!RelationTypeStore.relationTypes.length) {
            RelationTypeStore.fetchRelationTypes()
                .catch(() => {
                    notificationant.error({
                        message: t("notification.fetch.error.title"),
                        description: (
                            <Trans
                                i18nKey={"notification.fetch.error.description"}
                                values={{ itemType: t("relation-types.title") }}
                            />
                        ),
                    });
                });
        }
    }, []);

    const handleNavigate = (path: string) => {
        navigate(path);
      };

    return { generateClickableRelationTypeItems, handleNavigate };
};

export default useRelationTypesHook;