import React from 'react';

import { Route } from '@plasma/ui.application.router';

const About = React.lazy(() => import('.'));

export const AboutRoutes = [
  new Route({
    path: '/about',
    element: About,
  }),
];
