import React, { useMemo, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { notification as notificationant } from "antd";

import { useNavigate } from "@plasma/ui.application.router";

import { ClassDto } from "../../../models/dtos/class/ClassDtos";
import { useStore } from "../../../stores/store";

const useCreateClassHook = () => {
    const { ClassStore } = useStore();
    const [classToCreate, setClassToCreate] = useState<ClassDto>();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleCreateClass = async (data: ClassDto) => {
        await ClassStore.createClass(data)
            .then(() => {
                handleNavigate("/classes");
                notificationant.success({
                    message: t("notification.create.success.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.create.success.description"}
                            values={{ itemType: t("classes.title.single"), itemName: data.name }}
                        />
                    ),
                });
            })
            .catch(() => {
                notificationant.error({
                    message: t("notification.create.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.create.error.description"}
                            values={{ itemType: t("classes.title.single"), itemName: data.name }}
                        />
                    ),
                });
            });
    }

    const handleNavigate = (path: string) => {
        navigate(path);
      };

      const objectValid = useMemo(() => {
        return classToCreate?.name !== undefined && classToCreate?.name !== "" 
            && classToCreate?.code !== undefined && classToCreate?.code !== "";
    }, [classToCreate]);

    return {
        classToCreate,
        setClassToCreate,
        handleCreateClass,
        objectValid,
        handleNavigate,
    };
}

export default useCreateClassHook;