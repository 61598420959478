import { makeAutoObservable } from "mobx";

import services from "../api/agent";
import { RelationTypeCompleteDto, RelationTypeDto } from "../models/dtos/RelationTypeDtos";

export default class RelationTypeStore {
  relationTypes: RelationTypeCompleteDto[] = [];
  releationTypesAreLoading: boolean = false;

  chosenRelationTypeIsLoading: boolean = false;
  chosenRelationType: RelationTypeCompleteDto | null = null;

  // RelationTypes

  fetchRelationTypes = async () => {
    this.releationTypesAreLoading = true;
    
    await services.relationTypes.getAll()
    .then((response) => {
        this.relationTypes = response.data;
        this.releationTypesAreLoading = false;
    });
  }

  reloadRelationTypes = async () => {
    this.relationTypes = [];
    await this.fetchRelationTypes();
  };

  createRelationType = async (relationTypeToCreate: RelationTypeDto) => {
    await services.relationTypes.create(relationTypeToCreate).then((response) => {
      this.relationTypes.push(response.data);
    });
  };

  deleteRelationType = async (id: number) => {
    await services.relationTypes.delete(id).then(() => {
      const indexToRemove = this.relationTypes.findIndex((relationType) => relationType.id === id);
      this.relationTypes.splice(indexToRemove, 1);
    });
  };

  // Chosen relation type
  fetchChosenRelationType = async (id: number) => {
    this.chosenRelationTypeIsLoading = true;
    await services.relationTypes.getById(id)
        .then((respone) => {this.chosenRelationType=respone.data; this.chosenRelationTypeIsLoading=false;});
  };

  updateRelationType = async (relationTypeToUpdate: RelationTypeCompleteDto) => {
    const { id, ...updatedRelationType } = relationTypeToUpdate;
    await services.relationTypes.update(id, updatedRelationType).then((respone) => {
      const responseData = respone.data;
      this.chosenRelationType = responseData;
      this.relationTypes.map((relationType) => {
        if (relationType.id === responseData.id) {
          Object.assign(relationType, responseData);
        }
      });
    });
  };

  reloadChosenRelationType = async (id: number) => {
    this.chosenRelationType = null;
    await this.fetchChosenRelationType(id);
  };

  constructor() {
    makeAutoObservable(this);
  }
}