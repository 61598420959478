export class SystemAssociatedProfileDto {
    systemId: number;
    profileId: number;
    displayOrder?: number;

    constructor(systemId: number, profileId: number, displayOrder?: number) {
        this.systemId = systemId;
        this.profileId = profileId;
        this.displayOrder = displayOrder;
    };
}