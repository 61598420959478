import React from "react";

import ThemeProvider from "@plasma/ui.themes.theme-provider";
import { ThemeOverride } from "@plasma/ui.utils.types";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import ReactMarkdown from 'react-markdown'

import themes from "../../themes";
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";
import { MainContainer } from "./about.styles";
import markdownContent from "../../../CHANGELOG.md";

function About(props: Readonly<ThemeOverride>) {
    const { size, themeMode } = props;
    const { t } = useTranslation();

    return (
        <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
            <MainContainer>
                <Breadcrumbs newCurrentPath="/about" />
                    <h1 className="header"><strong>{t("about.title")}</strong></h1>
                    <ReactMarkdown className="content" children={`${markdownContent}`}/>
            </MainContainer>
        </ThemeProvider>
    );
}

export default observer(About);