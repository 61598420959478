import React, { useEffect, useMemo, useState } from "react";

import { useNavigate } from "@plasma/ui.application.router";
import { notification as notificationant } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { Column } from "@plasma/ui.display.data-grid";
import Button from '@plasma/ui.general.button';

import { useStore } from "../../../../stores/store";
import { ProfileAttributesData } from "./ProfileAttributesTab.types";
import services from "../../../../api/agent";

const useProfileAttributesTabHook = (chosenProfileId: number) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { ProfileStore } = useStore();
    const [groupByProfile, setGroupByProfile] = useState<boolean>(false);
    const [dataGridKey, setDataGridKey] = useState<number>(0);
    const [attributesData, setAttributesData] = useState<ProfileAttributesData[]>([]);
    const [attributesAreBuilding, setAttributesAreBuilding] = useState<boolean>(false);

    const buildAttributesData = async () => {
        setAttributesAreBuilding(true);
        
        const data: ProfileAttributesData[] = [];

        const promises = ProfileStore.attributes.map(async (attribute) => {
          const profilePromise = services.profiles
            .getById(attribute.profileId)
            .catch(() => {
                notificationant.error({
                    message: t("notification.fetch.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.fetch.error.description"}
                            values={{ itemType: t("profiles.title.single") }}
                        />
                    )
                });
            });
  
          const uomPromise = services.unitsOfMeasurement
            .getById(attribute.unitId)
            .catch(() => {
                notificationant.error({
                    message: t("notification.fetch.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.fetch.error.description"}
                            values={{ itemType: t("uoms.title.single") }}
                        />
                    )
                });
            });
  
          const [profile, uom] = await Promise.all([profilePromise, uomPromise]);
  
            if (profile && uom) {
                data.push(new ProfileAttributesData(
                    attribute.id,
                    profile.data.name,
                    attribute.name,
                    attribute.type,
                    uom.data.name,
                    attribute.description,
                ));
            }   
        });
  
        await Promise.all(promises);
        setAttributesData(data);
        setAttributesAreBuilding(false);
    };

    useEffect(() => {
        ProfileStore.fetchAttributes(chosenProfileId)
            .then(() => {
                buildAttributesData();
            })
            .catch(() => {
                notificationant.error({
                    message: t("notification.fetch.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.fetch.error.description"}
                            values={{ itemType: t("attributes.title") }}
                        />
                    ),
                });
            });
    }, []);

    const handleNavigate = (path: string) => {
        navigate(path);
    };

    const groupByColumn = useMemo(() => {
        return groupByProfile ? ["profileName"] : [];
    }, [groupByProfile]);

    const columns: Column<ProfileAttributesData>[] = useMemo(() => [
        {
            Header: t("table.profile"),
            accessor: "profileName",
        },
        {
            Header: t("table.name"),
            accessor: "name",
        },
        {
            Header: t("table.description"),
            accessor: "description",
        },
        {
            Header: t("table.data-type"),
            accessor: "dataType",
        },
        {
            Header: t("table.uom"),
            accessor: "uom",
        },
        {
            Header: t("table.actions"),
            accessor: "id",
            Cell: ({ row }: { row: any }) => {
                if (row.values.id) {
                    return (
                        <Button
                            onClick={() => {
                                const attributeProfileId = ProfileStore.attributes.find((attribute) => attribute.id === row.values.id)?.profileId;
                                handleNavigate(`/profiles/${attributeProfileId}/attributes/${row.values.id}`);
                            }}
                            type="tertiary"
                            title={t("button.details")}
                        />
                    );
                }
            }
        }
    ], []);

    return {
        handleNavigate,
        setGroupByProfile,
        groupByProfile,
        columns,
        attributesData,
        groupByColumn,
        dataGridKey,
        setDataGridKey,
        attributesAreBuilding
    };

};

export default useProfileAttributesTabHook;