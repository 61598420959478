import React, { useEffect } from "react";

import { Trans, useTranslation } from "react-i18next";
import { notification as notificationant } from "antd";
import { useNavigate } from "@plasma/ui.application.router";

import { useStore } from "../../stores/store";
import { ClickableItemMetadata } from "../../components/shared/ClickableItem/ClickableItem.types";

const useClassesHook = () => {
    const navigate = useNavigate();
    const { ClassStore } = useStore();
    const { t } = useTranslation();

    const generateClickableClassItems = () => {
        const clickableClassItems: ClickableItemMetadata[] = [];
        ClassStore.classes.map((item) => {
            clickableClassItems.push(new ClickableItemMetadata(item.id, item.name, `/classes/${item.id}`, item.description));
        });
        return clickableClassItems;
    }

    useEffect(() => {
        if (!ClassStore.classes.length) {
            ClassStore.fetchClasses()
                .catch(() => {
                    notificationant.error({
                        message: t("notification.fetch.error.title"),
                        description: (
                            <Trans
                                i18nKey={"notification.fetch.error.description"}
                                values={{ itemType: t("classes.title") }}
                            />
                        ),
                    });
                });
        }
    }, []);

    const handleNavigate = (path: string) => {
        navigate(path);
      };

    return { generateClickableClassItems, handleNavigate };
};

export default useClassesHook;