import React from "react";

import ThemeProvider from "@plasma/ui.themes.theme-provider";
import { ThemeOverride } from "@plasma/ui.utils.types";
import ScrollableArea from "@plasma/ui.layout.scrollable-area";
import { observer } from "mobx-react-lite";
import Button from "@plasma/ui.general.button";
import { useTranslation } from "react-i18next";

import themes from "../../themes";
import { MainContainer } from "./homepage.styles";
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";
import { ClickableItemMetadata } from "../../components/shared/ClickableItem/ClickableItem.types";
import ClickableItemCard from "../../components/shared/ClickableItem/ClickableItem";
import useHomepageHook from "./homepage.hook";
import SmsSpin from "../../components/core/SmsSpin/SmsSpin";
import { useStore } from "../../stores/store";

function Homepage(props: Readonly<ThemeOverride>) {
    const { size, themeMode } = props;
    const logic = useHomepageHook();
    const { SystemStore } = useStore();
    const { t } = useTranslation();

    const configItems = [
        new ClickableItemMetadata(1, t("classes.title"), "/classes", t("classes.description")),
        new ClickableItemMetadata(2, t("profiles.title"), "/profiles", t("profiles.description")),
        new ClickableItemMetadata(3, t("relation-types.title"), "/relation-types", t("relation-types.description")),
    ];

    return (
        <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
            <MainContainer>
                <Breadcrumbs newCurrentPath="/" />
                {SystemStore.systemsAreLoading ? <SmsSpin/> : 
                    <>
                        <div className="systems">
                            <h1><strong>{t("systems.title")}</strong></h1>
                            <Button
                                className="create-system-button"
                                title={t("button.create.system")}
                                type="primary"
                                onClick={() => logic.handleNavigate("/create-system")} 
                            />
                            <ScrollableArea className="scrollable-area">
                                <div className="item-container">
                                    {
                                        logic.generateClickableSystemItems().map((item) => (
                                            <ClickableItemCard
                                                key={item.id}
                                                item={item}
                                                iconName="Factory"
                                                onSelect={() => logic.handleNavigate(`/${item.id}`)}
                                            />
                                        ))
                                    }
                                </div>
                            </ScrollableArea>
                        </div>
                        <div className="basic-configuration">
                            <h1><strong>{t("configuration.title")}</strong></h1>
                            <div className="item-container">
                                {configItems.map((item) => (
                                    <ClickableItemCard
                                        key={item.id}
                                        item={item}
                                        iconName="Settings"
                                        onSelect={() => logic.handleNavigate(item.path)}
                                    />
                                ))}
                            </div>
                        </div>
                    </>
                }
            </MainContainer>
        </ThemeProvider>
    );
}

export default observer(Homepage);