import React from "react";

import ThemeProvider from "@plasma/ui.themes.theme-provider";
import { ThemeOverride } from "@plasma/ui.utils.types";
import { useParams } from '@plasma/ui.application.router';
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import Form from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import TextInput from "@plasma/ui.input.text-input";
import TextArea from "@plasma/ui.input.text-area";
import Select from "@plasma/ui.input.select";
import Switch from "@plasma/ui.input.switch";


import themes from "../../../../themes";
import Breadcrumbs from "../../../../components/shared/Breadcrumbs/Breadcrumbs";
import SmsSpin from "../../../../components/core/SmsSpin/SmsSpin";
import OpenDeleteModalButton from "../../../../components/shared/OpenDeleteModalButton/OpenDeleteModalButton";
import ItemDeleteModal from "../../../../components/shared/ItemDeleteModal/ItemDeleteModal";
import { useStore } from "../../../../stores/store";
import { MainContainer } from "./attribute-overview.styles";
import { AttributeCompleteDto } from "../../../../models/dtos/AttributeDtos";
import FormButtons from "../../../../components/shared/FormButtons/FormButtons";
import useAttributeOverviewHook from "./attribute-overview.hook";

function AttributeOverview(props: Readonly<ThemeOverride>) {
    const { size, themeMode } = props;
    const { t } = useTranslation();
    const { ProfileStore, UnitOfMeasurementStore } = useStore();
    const { profileId } = useParams();
    const { attributeId } = useParams();
    const logic = useAttributeOverviewHook(Number(profileId), Number(attributeId));

    return (
        <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
            <MainContainer>
                {
                    ProfileStore.chosenProfileIsLoading 
                    || UnitOfMeasurementStore.unitsOfMeasurementAreLoading 
                    || ProfileStore.chosenAttributeIsLoading
                    ? (
                    <SmsSpin />
                ) : (
                    <>
                        <Breadcrumbs
                            newCurrentPath={`/profiles/${profileId}/attributes/${attributeId}`}
                            chosenProfile={ProfileStore.chosenProfile?.name}
                            chosenProfileId={Number(profileId)}
                            chosenAttribute={ProfileStore.chosenAttribute?.name}
                            chosenAttributeId={Number(attributeId)}
                        />
                        <div className="overview">
                            <div className="header">
                                <h1><strong>{ProfileStore.chosenAttribute?.name}</strong></h1>
                                <OpenDeleteModalButton setDeleteAssetModalOpened={logic.setDeleteModalOpened}/>
                            </div>
                            <Form 
                                className="form"
                                onChange={(data: Partial<AttributeCompleteDto>) => logic.setAttributeToUpdate(data as AttributeCompleteDto)}
                                key={logic.formKey}
                                initialValues={ProfileStore.chosenAttribute!}
                            >
                                <FormField required label={t("form.name")} name="name">
                                    <TextInput required/>
                                </FormField>
                                <FormField label={t("form.description")} name="description">
                                    <TextArea rows={5}/>
                                </FormField>
                                <FormField required label={t("form.data-type")} name="type">
                                    <Select options={logic.typeSelectOptions}/>
                                </FormField>
                                <FormField required label={t("form.source")} name="source">
                                    <Select options={logic.sourceSelectOptions}/>
                                </FormField>
                                <FormField required label={t("form.uom")} name="unitId">
                                    <Select options={logic.uomSelectOptions}/>
                                </FormField>
                                <FormField required label={t("form.is-required")} name="mandatory">
                                    <Switch defaultChecked={ProfileStore.chosenAttribute?.mandatory}/>
                                </FormField>
                            </Form>
                            <FormButtons 
                                objectValid={logic.objectValid} 
                                handleOnSave={logic.handleUpdateAttribute}
                                itemToSave={logic.attributeToUpdate}
                                handleOnCancel={logic.onCancelChanges}
                                objectChanged={logic.objectChanged}
                            />
                        </div>
                        <ItemDeleteModal
                            parentChosenItemId={Number(profileId)}
                            childrenChosenItem={ProfileStore.chosenAttribute}
                            itemType={t("attribute.title.single")}
                            deleteItemModalOpened={logic.deleteModalOpened}
                            setDeleteItemModalOpened={logic.setDeleteModalOpened}
                            onDelete={logic.handleOnDelete}
                        />
                    </>
                )}
            </MainContainer>
        </ThemeProvider>
    );
}

export default observer(AttributeOverview);