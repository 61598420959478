import { createContext, useContext } from 'react';

import SystemStore from './SystemStore';
import ClassStore from './ClassStore';
import RelationTypeStore from './RelationTypeStore';
import FeatureStore from './FeatureStore';
import ProfileStore from './ProfileStore';
import UnitOfMeasurementStore from './UnitOfMeasurementStore';

interface Store {
    SystemStore: SystemStore;
    ClassStore: ClassStore;
    RelationTypeStore: RelationTypeStore;
    FeatureStore: FeatureStore;
    ProfileStore: ProfileStore;
    UnitOfMeasurementStore: UnitOfMeasurementStore;
}

const store: Store = {
    SystemStore: new SystemStore(),
    ClassStore: new ClassStore(),
    RelationTypeStore: new RelationTypeStore(),
    FeatureStore: new FeatureStore(),
    ProfileStore: new ProfileStore(),
    UnitOfMeasurementStore: new UnitOfMeasurementStore(),
};

const StoreContext = createContext(store);

export const useStore = () => useContext(StoreContext);
