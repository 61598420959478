import React from 'react';

import { Route } from '@plasma/ui.application.router';

const Homepage = React.lazy(() => import('.'));

export const HomepageRoutes = [
  new Route({
    path: '/',
    element: Homepage,
  }),
];
