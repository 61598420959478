import baseStyled, { ThemedStyledInterface, } from 'styled-components';

import { SemanticsTheme } from '../../../themes/types';

const styled = baseStyled as ThemedStyledInterface<SemanticsTheme>;

export const MainContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow: auto;
    height: 100%;

    .overview {
        padding: 16px;
    }

    .header {
        display: flex;
        justify-content: left;
        gap: 16px;
        align-items: center;
    }
    
    .tabs {
        margin-top: 16px;
    }

    .tab-panel {
        padding: 16px;
    }
`