import React, { useState } from "react";

import Breadcrumb from '@plasma/ui.navigation.breadcrumb';
import { BreadcrumbRoute } from "@plasma/ui.navigation.breadcrumb/dist/breadcrumb";
import { useTranslation } from "react-i18next";

import { BreadcrumbsContiner } from "./Breadcrumbs.styles";
import useBreadcrumbHook from "./Breadcrumbs.hook";

interface Props {
    newCurrentPath: string;
    chosenSystemId?: number;
    chosenSystem?: string;
    chosenClassId?: number;
    chosenClass?: string;
    chosenProfileId?: number;
    chosenProfile?: string;
    chosenRelationTypeId?: number;
    chosenRelationType?: string;
    chosenAttributeId?: number;
    chosenAttribute?: string;
}

function Breadcrumbs(props: Props) {
    const { t } = useTranslation();
    const logic = useBreadcrumbHook();

    const routes: BreadcrumbRoute[] = [{ 
        path: '/', 
        title: t("home.title"),
        children: [
            {
                path: '/create-system',
                title: t("systems.create.title")
            },
            { 
                path: props.chosenSystemId ? `/${props.chosenSystemId.toString()}` : '',
                title: props.chosenSystem ? props.chosenSystem : '',
            },
            {
                path: '/about',
                title: t("about.title")
            },
            {
                path: '/classes',
                title: t("classes.title"),
                children: [
                    {
                        path: '/classes/create',
                        title: t("classes.create.title")
                    },
                    {
                        path: props.chosenClassId ? `/classes/${props.chosenClassId.toString()}` : '',
                        title: props.chosenClass ? props.chosenClass : ''
                    }
                ]
            },
            {
                path: '/profiles',
                title: t("profiles.title"),
                children: [
                    {
                        path: '/profiles/create',
                        title: t("profiles.create.title")
                    },
                    {
                        path: props.chosenProfileId ? `/profiles/${props.chosenProfileId.toString()}` : '',
                        title: props.chosenProfile ? props.chosenProfile : '',
                        children: [
                            {
                                path: props.chosenProfileId ? `/profiles/${props.chosenProfileId.toString()}/attributes` : '',
                                title: t("attributes.title"),
                                children: [
                                    {
                                        path:  props.chosenProfileId 
                                            ? `/profiles/${props.chosenProfileId.toString()}/attributes/create` : '',
                                        title: t("attributes.create.title")
                                    },
                                    {
                                        path: props.chosenProfileId && props.chosenAttributeId 
                                            ? `/profiles/${props.chosenProfileId.toString()}/attributes/${props.chosenAttributeId.toString()}` 
                                            : '',
                                        title: props.chosenAttribute ? props.chosenAttribute : ''
                                    }
                                ]
                            },
                        ]
                    }
                ],
            },
            {
                path: '/relation-types',
                title: t("relation-types.title"),
                children: [
                    {
                        path: '/relation-types/create',
                        title: t("relation-types.create.title")
                    },
                    {
                        path: props.chosenRelationTypeId ? `/relation-types/${props.chosenRelationTypeId.toString()}` : '',
                        title: props.chosenRelationType ? props.chosenRelationType : ''
                    }
                ]
            }
        ] 
    }];
    const [currentPath, setCurrentPath] = useState<string>(props.newCurrentPath);

    const attributesRegex = /^\/profiles\/\d+\/attributes$/;

    return (
        <BreadcrumbsContiner>
            <Breadcrumb
                showContext
                showLinks
                showHomeLabel
                routes={routes}
                currentPath={currentPath}
                onRouteChange={(route) => {
                    if (!attributesRegex.test(route.path)) {
                        setCurrentPath(route.path);
                        logic.handleNavigate(route.path)
                    } else {
                        setCurrentPath(`/profiles/${props.chosenProfileId}`);
                        logic.handleNavigate(`/profiles/${props.chosenProfileId}?tabKey=3`)
                    }
                }}
            />
        </BreadcrumbsContiner>
    )
}

export default Breadcrumbs;