import React, { useState } from "react";

import ThemeProvider from "@plasma/ui.themes.theme-provider";
import { ThemeOverride } from "@plasma/ui.utils.types";
import { useParams } from '@plasma/ui.application.router';
import Tabs, { CustomTabPane } from "@plasma/ui.navigation.tabs";
import { Icon } from "@plasma/ui.general.icon";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";


import themes from "../../../themes";
import Breadcrumbs from "../../../components/shared/Breadcrumbs/Breadcrumbs";
import SmsSpin from "../../../components/core/SmsSpin/SmsSpin";
import { MainContainer } from "./system-overview.styles";
import useSystemOverviewHook from "./system-overview.hook";
import OpenDeleteModalButton from "../../../components/shared/OpenDeleteModalButton/OpenDeleteModalButton";
import ItemDeleteModal from "../../../components/shared/ItemDeleteModal/ItemDeleteModal";
import { useStore } from "../../../stores/store";
import SystemGeneralTab from "../../../components/features/SystemOverview/SystemGeneralTab/SystemGeneralTab";
import SystemClassesTab from "../../../components/features/SystemOverview/SystemClassesTab/SystemClassesTab";
import SystemRelationTypesTab from "../../../components/features/SystemOverview/SystemRelationTypes/SystemRelationTypes";
import SystemProfilesTab from "../../../components/features/SystemOverview/SystemProfilesTab/SystemProfilesTab";
import SystemFeaturesTab from "../../../components/features/SystemOverview/SystemFeaturesTab/SystemFeaturesTab";

function SystemOverview(props: Readonly<ThemeOverride>) {
    const { size, themeMode } = props;
    const { t } = useTranslation();
    const { SystemStore } = useStore();
    const { systemId } = useParams();
    const logic = useSystemOverviewHook(Number(systemId));
    const [deleteAssetModalOpened, setDeleteAssetModalOpened] = useState<boolean>(false);

    return (
        <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
            <MainContainer>
                {SystemStore.chosenSystemIsLoading ? (
                    <SmsSpin />
                ) : (
                    <>
                        <Breadcrumbs
                            newCurrentPath={`/${systemId}`}
                            chosenSystem={SystemStore.chosenSystem?.name}
                            chosenSystemId={Number(systemId)}
                        />
                        <div className="system-overview">
                            <div className="header">
                                <h1><strong>{SystemStore.chosenSystem?.name}</strong></h1>
                                <OpenDeleteModalButton setDeleteAssetModalOpened={setDeleteAssetModalOpened}/>
                            </div>
                            <Tabs className="tabs" type="line" destroyInactiveTabPane={true}>
                                <Tabs.TabPane
                                    className="tab-panel"
                                    key={1}
                                    tab={
                                        <CustomTabPane
                                            icon={<Icon name="Sell" variant="filled" />}
                                            title={t("tab.general")}
                                        />
                                    }
                                >
                                    <SystemGeneralTab/>
                                </Tabs.TabPane>
                                <Tabs.TabPane
                                    className="tab-panel"
                                    key={2}
                                    tab={
                                        <CustomTabPane
                                            icon={<Icon name="Extension" variant="filled" />}
                                            title={t("tab.classes")}
                                        />
                                    }
                                >
                                    <SystemClassesTab/>
                                </Tabs.TabPane>
                                <Tabs.TabPane
                                    className="tab-panel"
                                    key={3}
                                    tab={
                                        <CustomTabPane
                                            icon={<Icon name="StackedLineChart" variant="filled" />}
                                            title={t("tab.features")}
                                        />
                                    }
                                >
                                    <SystemFeaturesTab/>
                                </Tabs.TabPane>
                                <Tabs.TabPane
                                    className="tab-panel"
                                    key={4}
                                    tab={
                                        <CustomTabPane
                                            icon={<Icon name="Package" variant="filled" />}
                                            title={t("tab.profiles")}
                                        />
                                    }
                                >
                                    <SystemProfilesTab/>
                                </Tabs.TabPane>
                                <Tabs.TabPane
                                    className="tab-panel"
                                    key={5}
                                    tab={
                                        <CustomTabPane
                                            icon={<Icon name="Link" variant="filled" />}
                                            title={t("tab.relation-types")}
                                        />
                                    }
                                >
                                    <SystemRelationTypesTab/>
                                </Tabs.TabPane>
                            </Tabs>
                        </div>
                        <ItemDeleteModal
                            childrenChosenItem={SystemStore.chosenSystem}
                            itemType={t("systems.title.single")}
                            deleteItemModalOpened={deleteAssetModalOpened}
                            setDeleteItemModalOpened={setDeleteAssetModalOpened}
                            onDelete={logic.handleOnDelete}
                        />
                    </>
                )}
            </MainContainer>
        </ThemeProvider>
    );
}

export default observer(SystemOverview);