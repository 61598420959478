import baseStyled, { ThemedStyledInterface, } from 'styled-components';

import { SemanticsTheme } from '../../../themes/types';

const styled = baseStyled as ThemedStyledInterface<SemanticsTheme>;

export const MainContainer = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: row;
    gap: 10px;
`