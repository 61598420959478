import React from "react";

import Form from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import TextInput from "@plasma/ui.input.text-input";
import TextArea from "@plasma/ui.input.text-area";
import Switch from "@plasma/ui.input.switch";
import { useTranslation } from "react-i18next";

import FormButtons from "../../../shared/FormButtons/FormButtons";
import { ClassCompleteDto } from "../../../../models/dtos/class/ClassDtos";
import { TabContainer } from "./ClassGeneralTab.styles";
import useClassGeneralHook from "./ClassGeneralTab.hook";
import { useStore } from "../../../../stores/store";

function ClassGeneralTab() {
    const { t } = useTranslation();
    const logic = useClassGeneralHook();
    const { ClassStore } = useStore();

    return (
        <TabContainer>
            <Form 
                className="form"
                onChange={(data: Partial<ClassCompleteDto>) => logic.setUpdatedClass(data as ClassCompleteDto)}
                initialValues={ClassStore.chosenClass!}
                key={logic.formKey}
            >
                <FormField required label={t("form.name")} name="name">
                    <TextInput required/>
                </FormField>
                <FormField label={t("form.code")} name="code">
                    <TextArea rows={5}/>
                </FormField>
                <FormField label={t("form.description")} name="description">
                        <TextArea rows={5}/>
                </FormField>
                <FormField required label={t("form.allow-multiple-profiles")} name="allowMultipleProfiles">
                    <Switch defaultChecked={ClassStore.chosenClass?.allowMultipleProfiles}/>
                </FormField>
                <FormField required label={t("form.is-transactional")} name="isTransactional">
                    <Switch defaultChecked={ClassStore.chosenClass?.isTransactional}/>
                </FormField>
            </Form>
            <FormButtons 
                objectValid={logic.objectValid}
                objectChanged={logic.objectChanged}
                handleOnSave={logic.onUpdateClass}
                itemToSave={logic.updatedClass}
                handleOnCancel={logic.onCancelChanges}
            />
        </TabContainer>

    );
}

export default ClassGeneralTab;