import baseStyled, { ThemedStyledInterface, } from 'styled-components';

import { SemanticsTheme } from '../../themes/types';

const styled = baseStyled as ThemedStyledInterface<SemanticsTheme>;

export const MainContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow: auto;
    height: 100%;

    .create-class-button {
        margin-top: 16px;
    }

    .classes {
        width: 100%;
        flex-grow: 1;
        padding: 16px;
    }
    
    .item-container {
        padding-top: 16px;
        gap: 16px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content: flex-start;
        overflow-y: auto;
    }
`