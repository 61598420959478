import Button from "@plasma/ui.general.button";
import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";

interface DeleteButtonProps {
    setDeleteAssetModalOpened: Dispatch<boolean>;
};

function OpenDeleteModalButton(props: DeleteButtonProps) {
    const { t } = useTranslation();

  return (
    <Button
        type="secondary"
        onClick={() => {
          props.setDeleteAssetModalOpened(true);
        }}
        prefixIcon={{ name: "delete", variant: "filled" }}
        title={t("button.delete")}
      />
  );

}

export default OpenDeleteModalButton;