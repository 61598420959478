import React, { useEffect, useMemo, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { notification as notificationant } from "antd";

import { useStore } from "../../../../stores/store";
import { TransferData } from "../../../../models/types/TransferData";
import { ClassCompleteDto } from "../../../../models/dtos/class/ClassDtos";
import { checkObjectsAreEqual } from "../../../../utils/objectsEqualityChecker";
import services from "../../../../api/agent";
import { SystemAssociatedClassDto } from "../../../../models/dtos/system/SystemAssociatedClassDto";
import { identifyItemsToAdd } from "../../../../utils/itemsToAddIdentifier";
import { identifyItemsToRemove } from "../../../../utils/itemsToRemoveIdentifier";

const useSystemClassesTabHook = () => {
    const { SystemStore } = useStore();
    const { ClassStore } = useStore();
    const [initialTargetKeys, setInitialTargetKeys] = useState<string[]>([]);
    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [transferData, setTransferData] = useState<TransferData[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        setTransferData([]);
        setTargetKeys([]);
        if (!ClassStore.classes.length) {
            ClassStore.fetchClasses()
                .then(() => {
                    buildTransferData(ClassStore.classes);
                })
                .catch(() => {
                    notificationant.error({
                        message: t("notification.fetch.error.title"),
                        description: (
                            <Trans
                                i18nKey={"notification.fetch.error.description"}
                                values={{ itemType: t("classes.title") }}
                            />
                        )
                    });
                });
        } else {
            buildTransferData(ClassStore.classes);
        };
        SystemStore.reloadAssociatedClasses(SystemStore.chosenSystem!.id)
            .then(() => {
                buildTargetKeys(SystemStore.associatedClasses);
            })
            .catch(() => {
                notificationant.error({
                    message: t("notification.fetch.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.fetch.error.description"}
                            values={{ itemType: t("classes.associated") }}
                        />
                    )
                });
            });
    }, []);

    const buildTargetKeys = (associatedClasses: ClassCompleteDto[]) => {
        const associatedClasseIds = associatedClasses.map((c) => c.id.toString());
        setInitialTargetKeys(associatedClasseIds);
        setTargetKeys(associatedClasseIds);
    };

    const buildTransferData = (classes: ClassCompleteDto[]) => {
        classes.map((c) => {
            setTransferData((prev) => [...prev, new TransferData(c.id.toString(), c.name, c.description)]);
        });
    };

    const handleOnChange = (nextTargetKeys: string[]) => {
        setTargetKeys(nextTargetKeys);
    };

    const objectChanged = useMemo(() => {
        return !checkObjectsAreEqual(initialTargetKeys ?? [], targetKeys ?? []);
    }, [initialTargetKeys, targetKeys]);

    const handleOnCancel = () => {
        setTargetKeys(initialTargetKeys);
    };

    const handleOnSave = async (selectedTargetKeys: string[]) => {
        await handleOnUpdate(selectedTargetKeys);
        await SystemStore.reloadAssociatedClasses(SystemStore.chosenSystem!.id)
            .catch(() => {
                notificationant.error({
                    message: t("notification.fetch.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.fetch.error.description"}
                            values={{ itemType: t("classes.associated") }}
                        />
                    )
                });
            });
        buildTargetKeys(SystemStore.associatedClasses);

    };

    const handleOnUpdate = async (selectedTargetKeys: string[]) => {
        const classesToAdd = identifyItemsToAdd(initialTargetKeys, selectedTargetKeys, ClassStore.classes);
        const classesToRemove = identifyItemsToRemove(initialTargetKeys, selectedTargetKeys);

        const addPromises = classesToAdd.map(c => handleOnAdd(new SystemAssociatedClassDto(SystemStore.chosenSystem!.id, c.id)));
        const removePromises = classesToRemove.map(classId => handleOnRemove(Number(classId)));

        await Promise.all([...addPromises, ...removePromises]);
    };

    const handleOnAdd = async (data: SystemAssociatedClassDto) => {
        await services.systems.addClass(SystemStore.chosenSystem!.id, data)
            .then(() => {
                notificationant.success({
                    message: t("notification.create.success.title"),
                    description: (
                    <Trans
                        i18nKey={"notification.create.success.description"}
                        values={{ itemType: t("classes.associated"), itemName: "" }}
                    />
                    ),
                });
            })
            .catch(() => {
                notificationant.error({
                    message: t("notification.create.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.create.error.description"}
                            values={{ itemType: t("classes.associated"), itemName: "" }}
                        />
                    ),
                });
            });
    };

    const handleOnRemove = async (classId: number) => {
        await services.systems.removeClass(SystemStore.chosenSystem!.id, classId)
            .then(() => {
                notificationant.success({
                    message: t("notification.delete.success.title"),
                    description: (
                    <Trans
                        i18nKey={"notification.delete.success.description"}
                        values={{ itemType: t("classes.associated"), itemName: "" }}
                    />
                    ),
                });
            })
            .catch(() => {
                notificationant.error({
                    message: t("notification.update.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.update.error.description"}
                            values={{ itemType: t("classes.associated"), itemName: "" }}
                        />
                    ),
                });
            });
    };

    return {
        targetKeys,
        setTargetKeys,
        buildTransferData,
        transferData,
        handleOnChange,
        objectChanged,
        handleOnCancel,
        handleOnSave,
    };

};

export default useSystemClassesTabHook;
