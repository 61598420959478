import baseStyled, { ThemedStyledInterface, } from 'styled-components';

import { SemanticsTheme } from '../../../../themes/types';

const styled = baseStyled as ThemedStyledInterface<SemanticsTheme>;

export const TabContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow: auto;
    height: 100%;

    .transfer {
        min-width: 250px;
        padding: 9px;
    }
    
    .centered-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80vh;
    }
`