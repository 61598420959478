import baseStyled, { ThemedStyledInterface, } from 'styled-components';

import { SemanticsTheme } from '../../../themes/types';

const styled = baseStyled as ThemedStyledInterface<SemanticsTheme>;

export const ItemDeleteModalHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .modal-header {
    display: flex;
    align-items: center;
    font-size: 22px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .warning-message {
    color: #E10814;
  }

  .button-container {
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: flex-end;
  }
`;
