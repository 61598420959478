import React, { useMemo, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { notification as notificationant } from "antd";

import { useStore } from "../../../../stores/store";
import { checkObjectsAreEqual } from "../../../../utils/objectsEqualityChecker";
import { SelectOptions } from "../../../../models/types/SelectOptions";
import { RelationTypeCompleteDto } from "../../../../models/dtos/RelationTypeDtos";
import { RelationCardinalityType } from "../../../../models/enums/RelationTypeEnums";

const useRelationTypeGeneralHook = () => {
    const { RelationTypeStore, ClassStore } = useStore();
    const [updatedRelationType, setUpdatedRelationType] = useState<RelationTypeCompleteDto>(RelationTypeStore.chosenRelationType!);
    const [formKey, setFormKey] = useState(1);
    const { t } = useTranslation();

    const onUpdateRelationType = async (relationTypeToUpdate: RelationTypeCompleteDto) => {
        RelationTypeStore.updateRelationType(relationTypeToUpdate)
            .then(() => {
                setUpdatedRelationType(RelationTypeStore.chosenRelationType!);
                notificationant.success({
                    message: t("notification.update.success.title"),
                    description: (
                      <Trans
                        i18nKey={"notification.update.success.description"}
                        values={{ itemType: t("profiles.title.single"), itemName: relationTypeToUpdate.name }}
                      />
                    ),
                });
            })
            .catch(() => {
                notificationant.error({
                    message: t("notification.update.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.update.error.description"}
                            values={{ itemType: t("profiles.title.single"), itemName: relationTypeToUpdate.name }}
                        />
                    ),
                });
            });
    };

    const onCancelChanges = () => {
        setFormKey(formKey + 1);
        setUpdatedRelationType(RelationTypeStore.chosenRelationType!);
    };

    const objectValid = useMemo(() => {
        return updatedRelationType?.name !== undefined && updatedRelationType?.name !== ""
            && updatedRelationType?.cardinality !== undefined && updatedRelationType?.fromTypeId !== undefined
            && updatedRelationType?.toTypeId !== undefined;
    }, [updatedRelationType]);

    const objectChanged = useMemo(() => {
        return !checkObjectsAreEqual(RelationTypeStore.chosenRelationType ?? "", updatedRelationType ?? "");
      }, [RelationTypeStore.chosenRelationType, updatedRelationType]);

    const cardinalitySelectOptions = Object.keys(RelationCardinalityType)
        .map((key) => (new SelectOptions(RelationCardinalityType[key], key)));

    const classSelectOptions = useMemo(() => {
        return ClassStore.classes.map((item) => (new SelectOptions(item.id, item.name)));
    }, [ClassStore.classes]);

    return {
        objectValid,
        updatedRelationType,
        setUpdatedRelationType,
        formKey,
        onCancelChanges,
        objectChanged,
        onUpdateRelationType,
        classSelectOptions,
        cardinalitySelectOptions
    };
}

export default useRelationTypeGeneralHook;