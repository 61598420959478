import React from 'react';

import { Route } from '@plasma/ui.application.router';

const CreateSystem = React.lazy(() => import('.'));

export const CreateSystemRoutes = [
  new Route({
    path: '/create-system',
    element: CreateSystem,
  }),
];