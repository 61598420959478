export class ClassAssociatedFeatureDto {
    thingTypeId: number;
    featureTypeId: number;
    displayOrder?: number;

    constructor(thingTypeId: number, featureTypeId: number, displayOrder?: number) {
        this.thingTypeId = thingTypeId;
        this.featureTypeId = featureTypeId;
        this.displayOrder = displayOrder;
    };
}