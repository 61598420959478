import React from 'react';

import { Route } from '@plasma/ui.application.router';

const CreateClass = React.lazy(() => import('.'));

export const CreateClassRoutes = [
  new Route({
    path: '/classes/create',
    element: CreateClass,
  }),
];