import App from '@plasma/ui.application.app';
import { AvatarConfig } from '@plasma/ui.application.app/dist/app.types';
import { LocalStorageSettingsStore } from '@plasma/ui.utils.settings-store';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import keycloak from '../keycloak';
import { axiosInstance } from './api/agent';
import { applications } from './applications';
import { translations } from './i18n';
import './index.css';
import { routes } from './routes';

const applicationId = 'semantics-frontend';

const KeycloakWrapper = () => {
  const [isAuth, setAuth] = useState(false);
  const familyName: string = keycloak.idTokenParsed?.family_name;
  const firstName: string = keycloak.idTokenParsed?.given_name;
  const email: string = keycloak.idTokenParsed?.email;
  const user = {
    firstName: firstName,
    lastName: familyName,
    email: email
  };
  const avatarConfig: AvatarConfig = { hide: false, onLogout: () => keycloak.logout() };
  const handleTokens = (tokens: any) => {
    axiosInstance.interceptors.request.clear()
    axiosInstance.interceptors.request.use(
      (config: any) => {
        config.headers.Authorization = `Bearer ${tokens.token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
    if (tokens && tokens.token) {
      setAuth(true);
    }
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onTokens={handleTokens}
      initOptions={{
        onLoad: 'login-required',
        checkLoginIframe: false,
        enableLogging: true,
      }}
    >
      {isAuth ? (
        <App
          avatarConfig={avatarConfig}
          user={user}
          id={applicationId}
          routes={routes}
          applications={applications}
          translations={translations}
          isAuthenticated
          unauthorizedRedirectPath="/login"
          preferences={{
            theme: { options: ['light'], default: 'light' },
            menu: { options: ['top'], default: 'top' },
            size: {
              options: ['small', 'medium'],
              default: 'medium',
            },
            language: {
              options: ['de_DE', 'en-US'],
              default: 'en-US',
            },
          }}
          settingsStore={new LocalStorageSettingsStore(applicationId)}
        />
      ) : null}
    </ReactKeycloakProvider>
  );
};

ReactDOM.render(<KeycloakWrapper />, document.getElementById('root'));
