import { useNavigate } from "@plasma/ui.application.router";

const useBreadcrumbHook = () => {
    const navigate = useNavigate();

    const handleNavigate = (path: string) => {
        navigate(path);
    };

    return { handleNavigate };
};

export default useBreadcrumbHook;