import React from "react";

import Form from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import TextInput from "@plasma/ui.input.text-input";
import TextArea from "@plasma/ui.input.text-area";
import Select from "@plasma/ui.input.select";

import { useTranslation } from "react-i18next";
import FormButtons from "../../../../components/shared/FormButtons/FormButtons";
import { ProfileCompleteDto } from "../../../../models/dtos/profile/ProfileDtos";
import { useStore } from "../../../../stores/store";
import { TabContainer } from "./ProfileGeneralTab.styles";
import useProfileGeneralHook from "./ProfileGeneralTab.hook";

function ProfileGeneralTab() {
    const { t } = useTranslation();
    const logic = useProfileGeneralHook();
    const { ProfileStore } = useStore();

    return (
        <TabContainer>
            <>
                <Form 
                    className="form"
                    onChange={(data: Partial<ProfileCompleteDto>) => logic.setUpdatedProfile(data as ProfileCompleteDto)}
                    key={logic.formKey}
                    initialValues={ProfileStore.chosenProfile!}
                >
                    <FormField required label={t("form.name")} name="name">
                        <TextInput required/>
                    </FormField>
                    <FormField label={t("form.description")} name="description">
                        <TextArea rows={5}/>
                    </FormField>
                    <FormField required label={t("form.thing-type")} name="thingTypeId">
                        <Select options={logic.classSelectOptions}/>
                    </FormField>
                    <FormField required label={t("form.status")} name="status">
                        <Select options={logic.statusSelectOptions}/>
                    </FormField>
                </Form>
                <FormButtons 
                    objectValid={logic.objectValid}
                    objectChanged={logic.objectChanged}
                    handleOnSave={logic.onUpdateProfile}
                    itemToSave={logic.updatedProfile}
                    handleOnCancel={logic.onCancelChanges}
                />
            </>
        </TabContainer>
    );
}

export default ProfileGeneralTab;