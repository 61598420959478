import React from 'react';

import { Route } from '@plasma/ui.application.router';

const RelationTypeOverview = React.lazy(() => import('.'));

export const RelationTypeOverviewRoutes = [
  new Route({
    path: '/relation-types/:relationTypeId',
    element: RelationTypeOverview,
  }),
];