import { makeAutoObservable } from "mobx";

import services from "../api/agent";
import { FeatureCompleteDto } from "../models/dtos/FeatureDtos";

export default class FeatureStore {
  features: FeatureCompleteDto[] = [];
  featuresAreLoading: boolean = false;

  fetchFeatures = async () => {
    this.featuresAreLoading = true;
    
    await services.features.getAll()
    .then((response) => {
        this.features = response.data;
        this.featuresAreLoading = false;
    });
  }

  reloadFeatures = async () => {
    this.features = [];
    await this.fetchFeatures();
  };

  constructor() {
    makeAutoObservable(this);
  }
}