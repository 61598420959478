import { makeAutoObservable } from "mobx";

import services from "../api/agent";
import { ProfileCompleteDto, ProfileDto } from "../models/dtos/profile/ProfileDtos";
import { ProfilePartDto } from "../models/dtos/profile/ProfileComposition";
import { AttributeCompleteDto, AttributeDto } from "../models/dtos/AttributeDtos";

export default class ProfileStore {
  profiles: ProfileCompleteDto[] = [];
  profilesAreLoading: boolean = false;

  chosenProfile: ProfileCompleteDto | null = null;
  chosenProfileIsLoading: boolean = false;

  associatedProfiles: ProfilePartDto[] = [];
  associatedProfilesAreLoading: boolean = false;

  attributes: AttributeCompleteDto[] = [];
  attributesAreLoading: boolean = false;

  chosenAttribute: AttributeCompleteDto | null = null;
  chosenAttributeIsLoading: boolean = false;

  // Profiles
  fetchProfiles = async () => {
    this.profilesAreLoading = true;
    
    await services.profiles.getAll()
      .then((response) => {
          this.profiles = response.data;
          this.profilesAreLoading = false;
      });
  };

  createProfile = async (profileToCreate: ProfileDto) => {
    await services.profiles.create(profileToCreate).then((response) => {
      this.profiles.push(response.data);
    });
  };

  deleteProfile = async (id: number) => {
    await services.profiles.delete(id).then(() => {
      const indexToRemove = this.profiles.findIndex((profile) => profile.id === id);
      this.profiles.splice(indexToRemove, 1);
    });
  };

  reloadProfiles = async () => {
    this.profiles = [];
    await this.fetchProfiles();
  };

  // Chosen Profile
  fetchChosenProfile = async (id: number) => {
    this.chosenProfileIsLoading = true;
    
    await services.profiles.getById(id)
      .then((response) => {
          this.chosenProfile = response.data;
          this.chosenProfileIsLoading = false;
      });
  };

  updateProfile = async (profileToUpdate: ProfileCompleteDto) => {
    const { id, ...updatedProfile } = profileToUpdate;
    await services.profiles.update(id, updatedProfile).then((respone) => {
      const responseData = respone.data;
      this.chosenProfile = responseData;
      this.profiles.map((profile) => {
        if (profile.id === responseData.id) {
          Object.assign(profile, responseData);
        }
      });
    });
  };

  reloadChosenProfile = async (id: number) => {
    this.chosenProfile = null;
    await this.fetchChosenProfile(id);
  };

  // Associated Profiles
  fetchAssociatedProfiles = async (id: number) => {
    this.associatedProfilesAreLoading = true;
    
    await services.profiles.getCompositions(id)
      .then((response) => {
          this.associatedProfiles = response.data;
          this.associatedProfilesAreLoading = false;
      });
  };

  reloadAssociatedProfiles = async (id: number) => {
    this.associatedProfiles = [];
    await this.fetchAssociatedProfiles(id);
  };

  // Attributes
  fetchAttributes = async (profileId: number) => {
    this.attributesAreLoading = true;

    await services.profiles.getAllAttributes(profileId)
      .then((response) => {
          this.attributes = response.data;
          this.attributesAreLoading = false;
      });
  };

  reloadAttributes = async (profileId: number) => {
    this.attributes = [];
    await this.fetchAttributes(profileId);
  };

  createAttribute = async (profileId: number, attributeToCreate: AttributeDto) => {
    await services.profiles.createAttribute(profileId, attributeToCreate).then((response) => {
      this.attributes.push(response.data);
    });
  };

  deleteAttribute = async (profileId: number, attributeId: number) => {
    await services.profiles.deleteAttribute(profileId, attributeId).then(() => {
      const indexToRemove = this.attributes.findIndex((attribute) => attribute.id === attributeId);
      this.attributes.splice(indexToRemove, 1);
    });
  };

  // Chosen Attribute
  fetchChosenAttribute = async (profileId: number, attributeId: number) => {
    this.chosenAttributeIsLoading = true;
    
    await services.profiles.getAttributeById(profileId, attributeId)
      .then((response) => {
          this.chosenAttribute = response.data;
          this.chosenAttributeIsLoading = false;
      });
  };

  updateAttribute = async (profileId: number, attributeToUpdate: AttributeCompleteDto) => {
    const { id, ...updatedAttribute } = attributeToUpdate;
    await services.profiles.updateAttribute(profileId, id, updatedAttribute).then((respone) => {
      const responseData = respone.data;
      this.chosenAttribute = responseData;
      this.attributes.map((attribute) => {
        if (attribute.id === responseData.id) {
          Object.assign(attribute, responseData);
        }
      });
    });
  };

  constructor() {
    makeAutoObservable(this);
  }
}