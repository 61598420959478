import React from 'react';

import IconTitle from '@plasma/ui.display.icon-title';

import { StyledCard } from './ClickableItem.styles';
import { ClickableItemMetadata } from './ClickableItem.types';

export interface ClickableItemCardProps {
  item: ClickableItemMetadata;
  iconName: string;
  onSelect: () => void;
}

const ClickableItemCard = ({ item, iconName, onSelect }: ClickableItemCardProps) => {
  return (
    <StyledCard
      onClick={() => onSelect()}
    >
      <IconTitle
        icon={iconName}
        variant='filled'
        title={<h2 className='title'>{item.title}</h2>}
      />
      <p className="subtitle">{item.description}</p>
    </StyledCard>
  );
};

export default ClickableItemCard;