import React from "react";

import { useTranslation } from "react-i18next";
import Button from "@plasma/ui.general.button";
import Checkbox, { CheckboxChangeEvent } from "@plasma/ui.input.checkbox";
import DataGrid from "@plasma/ui.display.data-grid";
import { useParams } from '@plasma/ui.application.router';

import { useStore } from "../../../../stores/store";
import SmsSpin from "../../../core/SmsSpin/SmsSpin";
import { TabContainer } from "./ProfileAttributesTab.styles";
import useProfileAttributesTabHook from "./ProfileAttributesTab.hook";
import { ProfileAttributesData } from "./ProfileAttributesTab.types";

function ProfileAttributesTab() {
    const { ProfileStore } = useStore();
    const { profileId } = useParams();
    const { t } = useTranslation();
    const logic = useProfileAttributesTabHook(Number(profileId));

    return (
        <TabContainer>
            {ProfileStore.attributesAreLoading || logic.attributesAreBuilding ? (
                <div className="centered-spinner">
                    <SmsSpin/>
                </div>
            ) : (
                <>
                    <div className="toolbar">
                        <Button
                            title={t("button.create.attribute")}
                            type="primary" 
                            onClick={
                                () => logic.handleNavigate(
                                    `/profiles/${ProfileStore.chosenProfile?.id}/attributes/create`
                                )
                            } 
                        />
                        <Checkbox 
                            onChange={(e: CheckboxChangeEvent) => {
                                logic.setGroupByProfile(e.target.checked);
                                logic.setDataGridKey(logic.dataGridKey + 1);
                            }} 
                            checked={logic.groupByProfile} 
                            label={t("checkbox.group-by.profile")} 
                        />
                    </div>
                    <DataGrid<ProfileAttributesData>
                        key={logic.dataGridKey}
                        columns={logic.columns}
                        data={logic.attributesData}
                        disableToolHeader
                        disableDragAndDrop
                        disablePagination
                        groupColumns={logic.groupByColumn}
                        hideColumns={["profileName"]}
                        disableRowsSelection
                        groupColumnType={"groupColumn"}
                        ignoreListMaxHeight
                        listHeight={390}
                    />
                </>
            )}
        </TabContainer>
    );
};

export default ProfileAttributesTab;