import React from "react";

import ThemeProvider from "@plasma/ui.themes.theme-provider";
import { ThemeOverride } from "@plasma/ui.utils.types";
import Form from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import TextInput from "@plasma/ui.input.text-input";
import TextArea from "@plasma/ui.input.text-area";
import Switch from "@plasma/ui.input.switch"; 

import themes from "../../../themes";
import Breadcrumbs from "../../../components/shared/Breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { MainContainer } from "./create-class.styles";
import useCreateClassHook from "./create-class.hook";
import { ClassDto } from "../../../models/dtos/class/ClassDtos";
import FormButtons from "../../../components/shared/FormButtons/FormButtons";
import { observer } from "mobx-react-lite";

function CreateClass(props: Readonly<ThemeOverride>) {
    const { size, themeMode } = props;
    const { t } = useTranslation();
    const logic = useCreateClassHook();

    return (
        <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
            <MainContainer>
                <Breadcrumbs newCurrentPath="/classes/create" />
                <h1 className="header"><strong>{t("classes.create.title")}</strong></h1>
                <Form 
                    className="form"
                    onChange={(data: Partial<ClassDto>) => logic.setClassToCreate(data as ClassDto)}
                >
                    <FormField required label={t("form.code")} name="code">
                        <TextInput required/>
                    </FormField>
                    <FormField required label={t("form.name")} name="name">
                        <TextInput required/>
                    </FormField>
                    <FormField label={t("form.description")} name="description">
                        <TextArea rows={5}/>
                    </FormField>
                    <FormField required label={t("form.allow-multiple-profiles")} name="allowMultipleProfiles">
                        <Switch/>
                    </FormField>
                    <FormField required label={t("form.is-transactional")} name="isTransactional">
                        <Switch/>
                    </FormField>
                </Form>
                <FormButtons 
                    objectValid={logic.objectValid} 
                    handleOnSave={logic.handleCreateClass}
                    itemToSave={logic.classToCreate}
                    handleOnCancel={logic.handleNavigate}
                    onCancelParam={"/classes"}
                />
            </MainContainer>
        </ThemeProvider>
    );
}

export default observer(CreateClass);