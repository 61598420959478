export class ProfileAttributesData {
    id: number;
    profileName: string;
    name: string;
    description?: string;
    dataType: string;
    uom: string;

    constructor(id: number, profileName: string, name: string, dataType: string, uom: string, description?: string,) {
        this.id = id;
        this.profileName = profileName;
        this.name = name;
        this.description = description;
        this.dataType = dataType;
        this.uom = uom;
    }
}