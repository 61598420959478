import React from "react";

import Transfer from "@plasma/ui.input.transfer";

import FormButtons from "../../../shared/FormButtons/FormButtons";
import { useStore } from "../../../../stores/store";
import SmsSpin from "../../../core/SmsSpin/SmsSpin";
import { useTranslation } from "react-i18next";
import { TabContainer } from "./ProfileCompositionTab.styles";
import useProfileCompositionTabHook from "./ProfileCompositionTab.hook";

function ProfileCompositionTab() {
    const { ProfileStore } = useStore();
    const logic = useProfileCompositionTabHook();
    const { t } = useTranslation();

    return (
        <TabContainer>
            {
                ProfileStore.associatedProfilesAreLoading 
                || ProfileStore.profilesAreLoading
                ? (
                    <div className="centered-spinner">
                        <SmsSpin/>
                    </div>
                ) : (
                    <>
                        <Transfer
                            className="transfer"
                            dataSource={logic.transferData}
                            targetKeys={logic.targetKeys}
                            onChange={logic.handleOnChange}
                            titles={[t("transfer.available"), t("transfer.associated")]}
                            render={(item) => item.title}
                            listStyle={{height: "50vh", width: "70vh"}}
                        />
                        <FormButtons 
                            objectChanged={logic.objectChanged}
                            handleOnSave={logic.handleOnSave}
                            itemToSave={logic.targetKeys}
                            handleOnCancel={logic.handleOnCancel}
                        />
                    </>
                )
            }
        </TabContainer>
    );
};

export default ProfileCompositionTab;