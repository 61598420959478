import React, { useEffect, useMemo, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { notification as notificationant } from "antd";

import { useNavigate } from "@plasma/ui.application.router";

import { useStore } from "../../../stores/store";
import { RelationTypeDto } from "../../../models/dtos/RelationTypeDtos";
import { RelationCardinalityType } from "../../../models/enums/RelationTypeEnums";
import { SelectOptions } from "../../../models/types/SelectOptions";

const useCreateRelationTypeHook = () => {
    const { RelationTypeStore, ClassStore } = useStore();
    const [relationTypeToCreate, setRelationTypeToCreate] = useState<RelationTypeDto>();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (!ClassStore.classes.length) {
            ClassStore.fetchClasses()
                .catch(() => {
                    notificationant.error({
                        message: t("notification.fetch.error.title"),
                        description: (
                            <Trans
                                i18nKey={"notification.fetch.error.description"}
                                values={{ itemType: t("classes.title") }}
                            />
                        ),
                    });
                });
        }
    }, []);

    const handleCreateRelationType = async (data: RelationTypeDto) => {
        // Revisit this when BE supports constraint details
        data.isTreeShaped = false;
        data.isConstrained = false;
        await RelationTypeStore.createRelationType(data)
            .then(() => {
                handleNavigate("/relation-types");
                notificationant.success({
                    message: t("notification.create.success.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.create.success.description"}
                            values={{ itemType: t("relation-types.title.single"), itemName: data.name }}
                        />
                    ),
                });
            })
            .catch(() => {
                notificationant.error({
                    message: t("notification.create.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.create.error.description"}
                            values={{ itemType: t("relation-types.title.single"), itemName: data.name }}
                        />
                    ),
                });
            });
    }

    const handleNavigate = (path: string) => {
        navigate(path);
      };

      const objectValid = useMemo(() => {
        return relationTypeToCreate?.name !== undefined && relationTypeToCreate?.name !== "" 
        && relationTypeToCreate?.cardinality !== undefined && relationTypeToCreate?.fromTypeId !== undefined
        && relationTypeToCreate?.toTypeId !== undefined;
    }, [relationTypeToCreate]);

    const cardinalitySelectOptions = Object.keys(RelationCardinalityType)
        .map((key) => (new SelectOptions(RelationCardinalityType[key], key)));

    const classSelectOptions = useMemo(() => {
        return ClassStore.classes.map((item) => (new SelectOptions(item.id, item.name)));
    }, [ClassStore.classes]);

    return {
        relationTypeToCreate,
        setRelationTypeToCreate,
        handleCreateRelationType,
        objectValid,
        handleNavigate,
        cardinalitySelectOptions,
        classSelectOptions
    };
}

export default useCreateRelationTypeHook;