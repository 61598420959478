import React, { useMemo, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { notification as notificationant } from "antd";

import { useStore } from "../../../../stores/store";
import { checkObjectsAreEqual } from "../../../../utils/objectsEqualityChecker";
import { ProfileCompleteDto } from "../../../../models/dtos/profile/ProfileDtos";
import { ProfileStatus } from "../../../../models/enums/ProfileEnums";
import { SelectOptions } from "../../../../models/types/SelectOptions";

const useProfileGeneralHook = () => {
    const { ProfileStore } = useStore();
    const { ClassStore } = useStore();
    const [updatedProfile, setUpdatedProfile] = useState<ProfileCompleteDto>(ProfileStore.chosenProfile!);
    const [formKey, setFormKey] = useState(1);
    const { t } = useTranslation();

    const onUpdateProfile = async (profileToUpdate: ProfileCompleteDto) => {
        ProfileStore.updateProfile(profileToUpdate)
            .then(() => {
                setUpdatedProfile(ProfileStore.chosenProfile!);
                notificationant.success({
                    message: t("notification.update.success.title"),
                    description: (
                      <Trans
                        i18nKey={"notification.update.success.description"}
                        values={{ itemType: t("profiles.title.single"), itemName: profileToUpdate.name }}
                      />
                    ),
                });
            })
            .catch(() => {
                notificationant.error({
                    message: t("notification.update.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.update.error.description"}
                            values={{ itemType: t("profiles.title.single"), itemName: profileToUpdate.name }}
                        />
                    ),
                });
            });
    };

    const onCancelChanges = () => {
        setFormKey(formKey + 1);
        setUpdatedProfile(ProfileStore.chosenProfile!);
    };

    const objectValid = useMemo(() => {
        return updatedProfile?.name !== undefined && updatedProfile?.name !== "" 
            && updatedProfile?.thingTypeId !== undefined && updatedProfile?.status !== undefined;
    }, [updatedProfile]);

    const objectChanged = useMemo(() => {
        return !checkObjectsAreEqual(ProfileStore.chosenProfile ?? "", updatedProfile ?? "");
      }, [ProfileStore.chosenProfile, updatedProfile]);

    const statusSelectOptions = Object.keys(ProfileStatus).map((key) => (new SelectOptions(ProfileStatus[key], key)));

    const classSelectOptions = useMemo(() => {
        return ClassStore.classes.map((item) => (new SelectOptions(item.id, item.name)));
    }, [ClassStore.classes]);

    return {
        objectValid,
        updatedProfile,
        setUpdatedProfile,
        formKey,
        onCancelChanges,
        objectChanged,
        onUpdateProfile,
        statusSelectOptions,
        classSelectOptions
    };
}

export default useProfileGeneralHook;