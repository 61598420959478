import React, { useEffect, useMemo, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { notification as notificationant } from "antd";
import { useNavigate } from "@plasma/ui.application.router";

import { useStore } from "../../../../stores/store";
import { AttributeCompleteDto } from "../../../../models/dtos/AttributeDtos";
import { AttributeSource, AttributeType } from "../../../../models/enums/AttributeEnums";
import { SelectOptions } from "../../../../models/types/SelectOptions";
import { checkObjectsAreEqual } from "../../../../utils/objectsEqualityChecker";

const useAttributeOverviewHook = (chosenProfileId: number, chosenAttributeId: number) => {
    const navigate = useNavigate();
    const { ProfileStore, UnitOfMeasurementStore } = useStore();
    const { t } = useTranslation();
    const [deleteModalOpened, setDeleteModalOpened] = useState<boolean>(false);
    const [attributeToUpdate, setAttributeToUpdate] = useState<AttributeCompleteDto>();
    const [formKey, setFormKey] = useState<number>(0);

    useEffect(() => {
        ProfileStore.fetchChosenProfile(chosenProfileId)
            .catch(() => {
                notificationant.error({
                    message: t("notification.fetch.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.fetch.error.description"}
                            values={{ itemType: t("profiles.title.single") }}
                        />
                    ),
                });
            });
        ProfileStore.fetchChosenAttribute(chosenProfileId, chosenAttributeId)
            .then(() => {
                setAttributeToUpdate(ProfileStore.chosenAttribute!);
            })
            .catch(() => {
                notificationant.error({
                    message: t("notification.fetch.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.fetch.error.description"}
                            values={{ itemType: t("attributes.title.single") }}
                        />
                    ),
                });
            });
        if (!UnitOfMeasurementStore.unitsOfMeasurement.length) {
            UnitOfMeasurementStore.fetchUnitsOfMeasurement()
                .catch(() => {
                    notificationant.error({
                        message: t("notification.fetch.error.title"),
                        description: (
                            <Trans
                                i18nKey={"notification.fetch.error.description"}
                                values={{ itemType: t("uoms.title") }}
                            />
                        ),
                    });
                });
        }
    }, []);

    const handleNavigate = (path: string) => {
        navigate(path);
      };

    const handleOnDelete = async (attributeId: number, profileId?: number) => {
        await ProfileStore.deleteAttribute(profileId!, attributeId)
            .then(() => {
                handleNavigate(`/profiles/${chosenProfileId}?tabKey=3`);
                notificationant.success({
                    message: t("notification.delete.success.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.delete.success.description"}
                            values={{ itemType: t("attributes.title.single"), itemName: ProfileStore.chosenAttribute?.name }}
                        />
                    ),
                });
            })
            .catch(() => {
                handleNavigate(`/profiles/${chosenProfileId}?tabKey=3`);
                notificationant.error({
                    message: t("notification.delete.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.delete.error.description"}
                            values={{ itemType: t("attributes.title.single"), itemName: ProfileStore.chosenAttribute?.name }}
                        />
                    ),
                });
            });
    };

    const handleUpdateAttribute = async (updatedAttribute: AttributeCompleteDto) => {
        ProfileStore.updateAttribute(chosenProfileId, updatedAttribute)
            .then(() => {
                setAttributeToUpdate(ProfileStore.chosenAttribute!);
                notificationant.success({
                    message: t("notification.update.success.title"),
                    description: (
                      <Trans
                        i18nKey={"notification.update.success.description"}
                        values={{ itemType: t("attributes.title.single"), itemName: updatedAttribute.name }}
                      />
                    ),
                });
            })
            .catch(() => {
                notificationant.error({
                    message: t("notification.update.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.update.error.description"}
                            values={{ itemType: t("attributes.title.single"), itemName: updatedAttribute.name }}
                        />
                    ),
                });
            });
    };

    const onCancelChanges = () => {
        setFormKey(formKey + 1);
        setAttributeToUpdate(ProfileStore.chosenAttribute!);
    };


    const objectValid = useMemo(() => {
        return attributeToUpdate?.name !== undefined && attributeToUpdate?.name !== "" 
            && attributeToUpdate?.type !== undefined && attributeToUpdate?.source !== undefined
            && attributeToUpdate?.unitId !== undefined;
    }, [attributeToUpdate]);

    const objectChanged = useMemo(() => {
        return !checkObjectsAreEqual(ProfileStore.chosenAttribute ?? "", attributeToUpdate ?? "");
      }, [ProfileStore.chosenProfile, attributeToUpdate]);

    const typeSelectOptions = Object.keys(AttributeType)
        .map((key) => (new SelectOptions(AttributeType[key], key)));

    const sourceSelectOptions = Object.keys(AttributeSource)
        .map((key) => (new SelectOptions(AttributeSource[key], key)));

    const uomSelectOptions = useMemo(() => {
        return UnitOfMeasurementStore.unitsOfMeasurement
            .map((unit) => (new SelectOptions(unit.id, unit.name)));
    }, [UnitOfMeasurementStore.unitsOfMeasurement]);

    return {
        handleOnDelete,
        handleNavigate,
        deleteModalOpened,
        setDeleteModalOpened,
        attributeToUpdate,
        setAttributeToUpdate,
        objectValid,
        typeSelectOptions,
        uomSelectOptions,
        sourceSelectOptions,
        onCancelChanges,
        formKey,
        handleUpdateAttribute,
        objectChanged
    };
};

export default useAttributeOverviewHook;