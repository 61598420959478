import React, { useState } from "react";

import ThemeProvider from "@plasma/ui.themes.theme-provider";
import { ThemeOverride } from "@plasma/ui.utils.types";
import { useParams } from '@plasma/ui.application.router';
import Tabs, { CustomTabPane } from "@plasma/ui.navigation.tabs";
import { Icon } from "@plasma/ui.general.icon";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";


import themes from "../../../themes";
import Breadcrumbs from "../../../components/shared/Breadcrumbs/Breadcrumbs";
import SmsSpin from "../../../components/core/SmsSpin/SmsSpin";
import OpenDeleteModalButton from "../../../components/shared/OpenDeleteModalButton/OpenDeleteModalButton";
import ItemDeleteModal from "../../../components/shared/ItemDeleteModal/ItemDeleteModal";
import { useStore } from "../../../stores/store";
import useRelationTypeOverviewHook from "./relation-type-overview.hook";
import { MainContainer } from "./relation-type-overview.styles";
import RelationTypeGeneralTab from "../../../components/features/RelationTypeOverview/RelationTypeGeneralTab/RelationTypeGeneralTab";

function ProfileOverview(props: Readonly<ThemeOverride>) {
    const { size, themeMode } = props;
    const { t } = useTranslation();
    const { RelationTypeStore, ClassStore } = useStore();
    const { relationTypeId } = useParams();
    const logic = useRelationTypeOverviewHook(Number(relationTypeId));
    const [deleteModalOpened, setDeleteModalOpened] = useState<boolean>(false);

    return (
        <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
            <MainContainer>
                {RelationTypeStore.chosenRelationTypeIsLoading || ClassStore.classesAreLoading ? (
                    <SmsSpin />
                ) : (
                    <>
                        <Breadcrumbs
                            newCurrentPath={`/relation-types/${relationTypeId}`}
                            chosenRelationType={RelationTypeStore.chosenRelationType?.name}
                            chosenRelationTypeId={Number(relationTypeId)}
                        />
                        <div className="overview">
                            <div className="header">
                                <h1><strong>{RelationTypeStore.chosenRelationType?.name}</strong></h1>
                                <OpenDeleteModalButton setDeleteAssetModalOpened={setDeleteModalOpened}/>
                            </div>
                            <Tabs className="tabs" type="line" destroyInactiveTabPane={true}>
                                <Tabs.TabPane
                                    className="tab-panel"
                                    key={1}
                                    tab={
                                        <CustomTabPane
                                            icon={<Icon name="Sell" variant="filled" />}
                                            title={t("tab.general")}
                                        />
                                    }
                                >
                                    <RelationTypeGeneralTab/>
                                </Tabs.TabPane>
                            </Tabs>
                        </div>
                        <ItemDeleteModal
                            childrenChosenItem={RelationTypeStore.chosenRelationType}
                            itemType={t("relation-types.title.single")}
                            deleteItemModalOpened={deleteModalOpened}
                            setDeleteItemModalOpened={setDeleteModalOpened}
                            onDelete={logic.handleOnDelete}
                        />
                    </>
                )}
            </MainContainer>
        </ThemeProvider>
    );
}

export default observer(ProfileOverview);