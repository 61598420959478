import baseStyled, { ThemedStyledInterface, } from 'styled-components';

import { SemanticsTheme } from '../../themes/types';

const styled = baseStyled as ThemedStyledInterface<SemanticsTheme>;

export const MainContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow: auto;
    height: 100%;

    .header {
        width: 100%;
        flex-grow: 1;
        padding: 16px;
    }

    .content {
        padding-left: 32px;
    }
`