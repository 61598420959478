import React, { useEffect, useMemo, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { notification as notificationant } from "antd";

import { useStore } from "../../../../stores/store";
import { TransferData } from "../../../../models/types/TransferData";
import { checkObjectsAreEqual } from "../../../../utils/objectsEqualityChecker";
import services from "../../../../api/agent";
import { RelationTypeCompleteDto } from "../../../../models/dtos/RelationTypeDtos";
import { SystemAssociatedRelationTypeDto } from "../../../../models/dtos/system/SystemAssociatedRelationTypeDto";
import { identifyItemsToAdd } from "../../../../utils/itemsToAddIdentifier";
import { identifyItemsToRemove } from "../../../../utils/itemsToRemoveIdentifier";

const useSystemRelationTypesTabHook = () => {
    const { SystemStore } = useStore();
    const { RelationTypeStore } = useStore();
    const [initialTargetKeys, setInitialTargetKeys] = useState<string[]>([]);
    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [transferData, setTransferData] = useState<TransferData[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        setTransferData([]);
        setTargetKeys([]);
        if (!RelationTypeStore.relationTypes.length) {
            RelationTypeStore.fetchRelationTypes()
                .then(() => {
                    buildTransferData(RelationTypeStore.relationTypes);
                })
                .catch(() => {
                    notificationant.error({
                        message: t("notification.fetch.error.title"),
                        description: (
                            <Trans
                                i18nKey={"notification.fetch.error.description"}
                                values={{ itemType: t("relation-types.title") }}
                            />
                        ),
                    });
                });
        } else {
            buildTransferData(RelationTypeStore.relationTypes);
        };
        if (!SystemStore.associatedRelationTypes.length) {
            SystemStore.fetchAssociatedRelationTypes(SystemStore.chosenSystem!.id)
                .then(() => {
                    buildTargetKeys(SystemStore.associatedRelationTypes);
                })
                .catch(() => {
                    notificationant.error({
                        message: t("notification.fetch.error.title"),
                        description: (
                            <Trans
                                i18nKey={"notification.fetch.error.description"}
                                values={{ itemType: t("relation-types.associated") }}
                            />
                        ),
                    });
                });
        } else {
            buildTargetKeys(SystemStore.associatedRelationTypes);
        };
    }, []);

    const buildTargetKeys = (associatedRelationTypes: RelationTypeCompleteDto[]) => {
        const associatedRelationTypeIds = associatedRelationTypes.map((c) => c.id.toString());
        setInitialTargetKeys(associatedRelationTypeIds);
        setTargetKeys(associatedRelationTypeIds);
    };

    const buildTransferData = (relationTypes: RelationTypeCompleteDto[]) => {
        relationTypes.map((relationType) => {
            setTransferData((prev) => [...prev, new TransferData(
                relationType.id.toString(), 
                relationType.name, 
                relationType.description
            )]);
        });
    };

    const handleOnChange = (nextTargetKeys: string[]) => {
        setTargetKeys(nextTargetKeys);
    };

    const objectChanged = useMemo(() => {
        return !checkObjectsAreEqual(initialTargetKeys ?? [], targetKeys ?? []);
    }, [initialTargetKeys, targetKeys]);

    const handleOnCancel = () => {
        setTargetKeys(initialTargetKeys);
    };

    const handleOnSave = async (selectedTargetKeys: string[]) => {
        await handleOnUpdate(selectedTargetKeys);
        await SystemStore.reloadAssociatedRelationTypes(SystemStore.chosenSystem!.id)
            .catch(() => {
                notificationant.error({
                    message: t("notification.fetch.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.fetch.error.description"}
                            values={{ itemType: t("relation-types.associated") }}
                        />
                    ),
                });
            });
        buildTargetKeys(SystemStore.associatedRelationTypes);
    };

    const handleOnUpdate = async (selectedTargetKeys: string[]) => {
        const relationTypesToAdd = identifyItemsToAdd(initialTargetKeys, selectedTargetKeys, RelationTypeStore.relationTypes);
        const relationTypesToRemove = identifyItemsToRemove(initialTargetKeys, selectedTargetKeys);

        const addPromises = relationTypesToAdd.map(
            relationType => handleOnAdd(new SystemAssociatedRelationTypeDto(SystemStore.chosenSystem!.id, relationType.id))
        );
        const removePromises = relationTypesToRemove.map(
            relationTypeId => handleOnRemove(Number(relationTypeId))
        );

        await Promise.all([...addPromises, ...removePromises]);
    };

    const handleOnAdd = async (data: SystemAssociatedRelationTypeDto) => {
        await services.systems.addRelationType(SystemStore.chosenSystem!.id, data)
            .then(() => {
                notificationant.success({
                    message: t("notification.create.success.title"),
                    description: (
                    <Trans
                        i18nKey={"notification.create.success.description"}
                        values={{ itemType: t("relation-types.associated"), itemName: "" }}
                    />
                    ),
                });
            })
            .catch(() => {
                notificationant.error({
                    message: t("notification.create.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.create.error.description"}
                            values={{ itemType: t("relation-types.associated"), itemName: "" }}
                        />
                    ),
                });
            });
    };

    const handleOnRemove = async (relationTypeId: number) => {
        await services.systems.removeRelationType(SystemStore.chosenSystem!.id, relationTypeId)
            .then(() => {
                notificationant.success({
                    message: t("notification.delete.success.title"),
                    description: (
                    <Trans
                        i18nKey={"notification.delete.success.description"}
                        values={{ itemType: t("relation-types.associated"), itemName: "" }}
                    />
                    ),
                });
            })
            .catch(() => {
                notificationant.error({
                    message: t("notification.delete.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.delete.error.description"}
                            values={{ itemType: t("relation-types.associated"), itemName: "" }}
                        />
                    ),
                });
            });
    };

    return {
        targetKeys,
        setTargetKeys,
        buildTransferData,
        transferData,
        handleOnChange,
        objectChanged,
        handleOnCancel,
        handleOnSave,
    };

};

export default useSystemRelationTypesTabHook;
