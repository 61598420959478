import React, { useEffect, useMemo, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { notification as notificationant } from "antd";
import { useNavigate } from "@plasma/ui.application.router";

import { useStore } from "../../../../stores/store";
import { SelectOptions } from "../../../../models/types/SelectOptions";
import { AttributeDto } from "../../../../models/dtos/AttributeDtos";
import { AttributeSource, AttributeType } from "../../../../models/enums/AttributeEnums";

const useCreateAttributeHook = (profileId: number) => {
    const { ProfileStore, UnitOfMeasurementStore } = useStore();
    const [attributeToCreate, setAttributeToCreate] = useState<AttributeDto>();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (!ProfileStore.chosenProfile) {
            ProfileStore.fetchChosenProfile(profileId)
                .catch(() => {
                    notificationant.error({
                        message: t("notification.fetch.error.title"),
                        description: (
                            <Trans
                                i18nKey={"notification.fetch.error.description"}
                                values={{ itemType: t("profiles.title.single") }}
                            />
                        ),
                    });
                });
        };
        if (!UnitOfMeasurementStore.unitsOfMeasurement.length) {
            UnitOfMeasurementStore.fetchUnitsOfMeasurement()
                .catch(() => {
                    notificationant.error({
                        message: t("notification.fetch.error.title"),
                        description: (
                            <Trans
                                i18nKey={"notification.fetch.error.description"}
                                values={{ itemType: t("uoms.title") }}
                            />
                        ),
                    });
                });
        }
    }, []);

    const handleCreateAttribute = async (data: AttributeDto) => {
        await ProfileStore.createAttribute(profileId, data)
            .then(() => {
                handleNavigate(`/profiles/${profileId}?tabKey=3`);
                notificationant.success({
                    message: t("notification.create.success.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.create.success.description"}
                            values={{ itemType: t("attributes.title.single"), itemName: data.name }}
                        />
                    ),
                });
            });
    }

    const handleNavigate = (path: string) => {
        navigate(path);
      };

    const objectValid = useMemo(() => {
        return attributeToCreate?.name !== undefined && attributeToCreate?.name !== "" 
            && attributeToCreate?.type !== undefined && attributeToCreate?.source !== undefined
            && attributeToCreate?.unitId !== undefined;
    }, [attributeToCreate]);

    const typeSelectOptions = Object.keys(AttributeType)
        .map((key) => (new SelectOptions(AttributeType[key], key)));

    const sourceSelectOptions = Object.keys(AttributeSource)
        .map((key) => (new SelectOptions(AttributeSource[key], key)));

    const uomSelectOptions = useMemo(() => {
        return UnitOfMeasurementStore.unitsOfMeasurement
            .map((unit) => (new SelectOptions(unit.id, unit.name)));
    }, [UnitOfMeasurementStore.unitsOfMeasurement]);

    return {
        attributeToCreate,
        setAttributeToCreate,
        handleCreateAttribute,
        objectValid,
        handleNavigate,
        typeSelectOptions,
        uomSelectOptions,
        sourceSelectOptions,
    };
}

export default useCreateAttributeHook;