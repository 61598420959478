export enum AttributeType {
    Text = "Text",
    Number = "Number",
    Date = "Date",
    Boolean = "Boolean",
    LookUpTable = "LookUpTable",
}

export enum AttributeSource {
    Custom = "Custom",
    Standard = "Standard",
}
