import { makeAutoObservable } from "mobx";

import { UnitOfMeasurementCompleteDto } from "../models/dtos/UnitOfMeasurementDtos";
import services from "../api/agent";

export default class UnitOfMeasurementStore {
    unitsOfMeasurement: UnitOfMeasurementCompleteDto[] = [];
    unitsOfMeasurementAreLoading: boolean = false;

    fetchUnitsOfMeasurement = async () => {
        this.unitsOfMeasurementAreLoading = true;
        
        await services.unitsOfMeasurement.getAll()
        .then((response) => {
            this.unitsOfMeasurement = response.data;
            this.unitsOfMeasurementAreLoading = false;
        });
    };

    constructor() {
        makeAutoObservable(this);
      }
}