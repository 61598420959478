import React, { useState } from "react";

import ThemeProvider from "@plasma/ui.themes.theme-provider";
import { ThemeOverride } from "@plasma/ui.utils.types";
import { useParams, useLocation } from '@plasma/ui.application.router';
import Tabs, { CustomTabPane } from "@plasma/ui.navigation.tabs";
import { Icon } from "@plasma/ui.general.icon";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";


import themes from "../../../themes";
import Breadcrumbs from "../../../components/shared/Breadcrumbs/Breadcrumbs";
import SmsSpin from "../../../components/core/SmsSpin/SmsSpin";
import OpenDeleteModalButton from "../../../components/shared/OpenDeleteModalButton/OpenDeleteModalButton";
import ItemDeleteModal from "../../../components/shared/ItemDeleteModal/ItemDeleteModal";
import { useStore } from "../../../stores/store";
import useProfileOverviewHook from "./profile-overview.hook";
import { MainContainer } from "./profile-overview.styles";
import ProfileGeneralTab from "../../../components/features/ProfileOverview/ProfileGeneralTab/ProfileGeneralTab";
import ProfileCompositionTab from "../../../components/features/ProfileOverview/ProfileCompositionTab/ProfileCompositionTab";
import ProfileAttributesTab from "../../../components/features/ProfileOverview/ProfileAttributesTab/ProfileAttributesTab";

function ProfileOverview(props: Readonly<ThemeOverride>) {
    const { size, themeMode } = props;
    const { t } = useTranslation();
    const { ProfileStore } = useStore();
    const { ClassStore } = useStore();
    const { profileId } = useParams();
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const tabKey = queryParams.get('tabKey') || "1";
    const logic = useProfileOverviewHook(Number(profileId));
    const [deleteModalOpened, setDeleteModalOpened] = useState<boolean>(false);

    return (
        <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
            <MainContainer>
                {ProfileStore.chosenProfileIsLoading || ClassStore.classesAreLoading ? (
                    <SmsSpin />
                ) : (
                    <>
                        <Breadcrumbs
                            newCurrentPath={`/profiles/${profileId}`}
                            chosenProfile={ProfileStore.chosenProfile?.name}
                            chosenProfileId={Number(profileId)}
                        />
                        <div className="overview">
                            <div className="header">
                                <h1><strong>{ProfileStore.chosenProfile?.name}</strong></h1>
                                <OpenDeleteModalButton setDeleteAssetModalOpened={setDeleteModalOpened}/>
                            </div>
                            <Tabs 
                                className="tabs" 
                                type="line" 
                                destroyInactiveTabPane={true}
                                defaultActiveKey={tabKey}
                            >
                                <Tabs.TabPane
                                    className="tab-panel"
                                    key={1}
                                    tab={
                                        <CustomTabPane
                                            icon={<Icon name="Sell" variant="filled" />}
                                            title={t("tab.general")}
                                        />
                                    }
                                >
                                    <ProfileGeneralTab/>
                                </Tabs.TabPane>
                                <Tabs.TabPane
                                    className="tab-panel"
                                    key={2}
                                    tab={
                                        <CustomTabPane
                                            icon={<Icon name="GridView" variant="filled" />}
                                            title={t("tab.composition")}
                                        />
                                    }
                                >
                                    <ProfileCompositionTab/>
                                </Tabs.TabPane>
                                <Tabs.TabPane
                                    className="tab-panel"
                                    key={3}
                                    tab={
                                        <CustomTabPane
                                            icon={<Icon name="Apps" variant="filled" />}
                                            title={t("tab.attributes")}
                                        />
                                    }
                                >
                                    <ProfileAttributesTab/>
                                </Tabs.TabPane>
                            </Tabs>
                        </div>
                        <ItemDeleteModal
                            childrenChosenItem={ProfileStore.chosenProfile}
                            itemType={t("profiles.title.single")}
                            deleteItemModalOpened={deleteModalOpened}
                            setDeleteItemModalOpened={setDeleteModalOpened}
                            onDelete={logic.handleOnDelete}
                        />
                    </>
                )}
            </MainContainer>
        </ThemeProvider>
    );
}

export default observer(ProfileOverview);