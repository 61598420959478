import React, { useMemo, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { notification as notificationant } from "antd";

import { SystemCompleteDto } from "../../../../models/dtos/system/SystemDtos"
import { useStore } from "../../../../stores/store";
import { checkObjectsAreEqual } from "../../../../utils/objectsEqualityChecker";

const useSystemGeneralHook = () => {
    const { SystemStore } = useStore();
    const [updatedSystem, setUpdatedSystem] = useState<SystemCompleteDto>(SystemStore.chosenSystem!);
    const [formKey, setFormKey] = useState(1);
    const { t } = useTranslation();

    const onUpdateSystem = async (systemToUpdate: SystemCompleteDto) => {
        SystemStore.updateSystem(systemToUpdate)
            .then(() => {
                setUpdatedSystem(SystemStore.chosenSystem!);
                notificationant.success({
                    message: t("notification.update.success.title"),
                    description: (
                      <Trans
                        i18nKey={"notification.update.success.description"}
                        values={{ itemType: t("systems.title.single"), itemName: systemToUpdate.name }}
                      />
                    ),
                });
            })
            .catch(() => {
                notificationant.error({
                    message: t("notification.update.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.update.error.description"}
                            values={{ itemType: t("systems.title.single"), itemName: systemToUpdate.name }}
                        />
                    ),
                });
            });
    };

    const onCancelChanges = () => {
        setFormKey(formKey + 1);
        setUpdatedSystem(SystemStore.chosenSystem!);
    };

    const objectValid = useMemo(() => {
        return updatedSystem?.name !== undefined && updatedSystem?.name !== "";
    }, [updatedSystem]);

    const objectChanged = useMemo(() => {
        return !checkObjectsAreEqual(SystemStore.chosenSystem ?? "", updatedSystem ?? "");
      }, [SystemStore.chosenSystem, updatedSystem]);

    return {
        objectValid,
        setUpdatedSystem,
        updatedSystem,
        formKey,
        onCancelChanges,
        objectChanged,
        onUpdateSystem
    };
}

export default useSystemGeneralHook;