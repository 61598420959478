import deepEqual from 'deep-equal';

export const checkObjectsAreEqual = (obj1: any, obj2: any) => {
    const trimmedObj1 = 
        typeof obj1 === 'string' ? obj1.trim() : 
        Array.isArray(obj1) ? obj1.sort() : 
        obj1;

    const trimmedObj2 = 
        typeof obj2 === 'string' ? obj2.trim() :
        Array.isArray(obj2) ? obj2.sort() : 
        obj2;

    return deepEqual(trimmedObj1, trimmedObj2)
}