export class SystemAssociatedRelationTypeDto {
    systemId: number;
    thingRelationTypeId: number;
    displayOrder?: number;

    constructor(systemId: number, thingRelationTypeId: number, displayOrder?: number) {
        this.systemId = systemId;
        this.thingRelationTypeId = thingRelationTypeId;
        this.displayOrder = displayOrder;
    };
}