import baseStyled, { ThemedStyledInterface, } from 'styled-components';

import { SemanticsTheme } from '../../../themes/types';

const styled = baseStyled as ThemedStyledInterface<SemanticsTheme>;

export const BreadcrumbsContiner = styled.div`
    width: 100%;
    height: 48px;
    border-bottom: 1px solid #000000;
    padding: 16px;
`