import React from 'react';

import { Route } from '@plasma/ui.application.router';

const ClassOverview = React.lazy(() => import('.'));

export const ClassOverviewRoutes = [
  new Route({
    path: '/classes/:classId',
    element: ClassOverview,
  }),
];