import React from 'react';

import { Route } from '@plasma/ui.application.router';

const RelationTypes = React.lazy(() => import('.'));

export const RelationTypeRoutes = [
  new Route({
    path: '/relation-types',
    element: RelationTypes,
  }),
];
