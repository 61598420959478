import React from 'react';

import { Route } from '@plasma/ui.application.router';

const ProfileOverview = React.lazy(() => import('.'));

export const ProfileOverviewRoutes = [
  new Route({
    path: '/profiles/:profileId',
    element: ProfileOverview,
  }),
];