import React from "react";

import ThemeProvider from "@plasma/ui.themes.theme-provider";
import { ThemeOverride } from "@plasma/ui.utils.types";
import Form from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import TextInput from "@plasma/ui.input.text-input";
import TextArea from "@plasma/ui.input.text-area";
import Switch from "@plasma/ui.input.switch"; 
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import Select from "@plasma/ui.input.select";

import themes from "../../../themes";
import Breadcrumbs from "../../../components/shared/Breadcrumbs/Breadcrumbs";
import FormButtons from "../../../components/shared/FormButtons/FormButtons";
import useCreateRelationTypeHook from "./create-relation-type.hook";
import { MainContainer } from "./create-relation-type.styles";
import { RelationTypeDto } from "../../../models/dtos/RelationTypeDtos";
import SmsSpin from "../../../components/core/SmsSpin/SmsSpin";
import { useStore } from "../../../stores/store";

function CreateRelationType(props: Readonly<ThemeOverride>) {
    const { size, themeMode } = props;
    const { t } = useTranslation();
    const logic = useCreateRelationTypeHook();
    const { ClassStore } = useStore();

    return (
        <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
            <MainContainer>
                <Breadcrumbs newCurrentPath="/relation-types/create" />
                <h1 className="header"><strong>{t("relation-types.create.title")}</strong></h1>
                {ClassStore.classesAreLoading ? <SmsSpin/> : 
                    <>
                        <Form
                            className="form"
                            onChange={(data: Partial<RelationTypeDto>) => logic.setRelationTypeToCreate(data as RelationTypeDto)}
                        >
                            <FormField required label={t("form.name")} name="name">
                                <TextInput required/>
                            </FormField>
                            <div className="from-to-relation">
                                <FormField className="from-to-selections" required label={t("form.from-thing-type")} name="fromTypeId">
                                    <Select options={logic.classSelectOptions}/>
                                </FormField>
                                <FormField className="from-to-selections" required label={t("form.cardinality-type")} name="cardinality">
                                    <Select options={logic.cardinalitySelectOptions}/>
                                </FormField>
                                <FormField className="from-to-selections" required label={t("form.to-thing-type")} name="toTypeId">
                                    <Select options={logic.classSelectOptions}/>
                                </FormField>
                            </div>
                            <FormField label={t("form.description")} name="description">
                                <TextArea rows={5}/>
                            </FormField>
                            <FormField required label={t("form.allow-cycles")} name="isAcyclic">
                                <Switch/>
                            </FormField>
                        </Form>
                        <FormButtons 
                            objectValid={logic.objectValid} 
                            handleOnSave={logic.handleCreateRelationType}
                            itemToSave={logic.relationTypeToCreate}
                            handleOnCancel={logic.handleNavigate}
                            onCancelParam={"/relation-types"}
                        />
                    </>
                }
                
            </MainContainer>
        </ThemeProvider>
    );
}

export default observer(CreateRelationType);