import React, { useEffect, useMemo, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { notification as notificationant } from "antd";
import { useNavigate } from "@plasma/ui.application.router";

import { ClickableItemMetadata } from "../../components/shared/ClickableItem/ClickableItem.types";
import { useStore } from "../../stores/store";
import { ClassCompleteDto } from "../../models/dtos/class/ClassDtos";
import { SelectOptions } from "../../models/types/SelectOptions";

const useProfilesHook = () => {
    const navigate = useNavigate();
    const { ProfileStore } = useStore();
    const { ClassStore } = useStore();
    const [selectedClass, setSelectedClass] = useState<ClassCompleteDto>();
    const { t } = useTranslation();

    const generateClickableProfileItems = () => {
        const clickableProfileItems: ClickableItemMetadata[] = [];
        const suffiecientProfiles = ProfileStore.profiles.filter((profile) => profile.thingTypeId === selectedClass?.id);
        suffiecientProfiles.map((item) => {
            clickableProfileItems.push(new ClickableItemMetadata(item.id, item.name, `/profiles/${item.id}`, item.description));
        });
        return clickableProfileItems;
    }

    const clickableProfileItems = useMemo(() => generateClickableProfileItems(), [ProfileStore.profiles, selectedClass]);

    useEffect(() => {
        if (!ProfileStore.profiles.length) {
            ProfileStore.fetchProfiles()
                .catch(() => {
                    notificationant.error({
                        message: t("notification.fetch.error.title"),
                        description: (
                            <Trans
                                i18nKey={"notification.fetch.error.description"}
                                values={{ itemType: t("profiles.title") }}
                            />
                        ),
                    });
                });
        }
        if (!ClassStore.classes.length) {
            ClassStore.fetchClasses()
                .catch(() => {
                    notificationant.error({
                        message: t("notification.fetch.error.title"),
                        description: (
                            <Trans
                                i18nKey={"notification.fetch.error.description"}
                                values={{ itemType: t("classes.title") }}
                            />
                        ),
                    });
                });
        }
    }, []);

    const selectOptions = useMemo(() => {
        const options = ClassStore.classes.map((item) => (new SelectOptions(item.id, item.name)));
        if (options.length) {
            setSelectedClass(ClassStore.classes.find((item) => item.id === options[0].value)!);
        }
        return options;
    }, [ClassStore.classes]);

    const handleSelectOnChange = (selectedItemId: number) => {
        setSelectedClass(ClassStore.classes.find((item) => item.id === selectedItemId)!);
    };

    const handleNavigate = (path: string) => {
        navigate(path);
      };

    return { clickableProfileItems, handleNavigate, setSelectedClass, selectOptions, handleSelectOnChange };
};

export default useProfilesHook;