import React from "react";

import Form from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import TextInput from "@plasma/ui.input.text-input";
import TextArea from "@plasma/ui.input.text-area";
import { useTranslation } from "react-i18next";

import FormButtons from "../../../shared/FormButtons/FormButtons";
import { SystemCompleteDto } from "../../../../models/dtos/system/SystemDtos";
import { TabContainer } from "./SystemGeneralTab.styles";
import useSystemGeneralHook from "./SystemGeneralTab.hook";
import { useStore } from "../../../../stores/store";

function SystemGeneralTab() {
    const { t } = useTranslation();
    const logic = useSystemGeneralHook();
    const { SystemStore } = useStore();

    return (
        <TabContainer>
            <Form 
                className="form"
                onChange={(data: Partial<SystemCompleteDto>) => logic.setUpdatedSystem(data as SystemCompleteDto)}
                initialValues={SystemStore.chosenSystem!}
                key={logic.formKey}
            >
                <FormField required label={t("form.name")} name="name">
                    <TextInput required/>
                </FormField>
                <FormField label={t("form.description")} name="description">
                    <TextArea rows={5}/>
                </FormField>
            </Form>
            <FormButtons 
                objectValid={logic.objectValid}
                objectChanged={logic.objectChanged}
                handleOnSave={logic.onUpdateSystem}
                itemToSave={logic.updatedSystem}
                handleOnCancel={logic.onCancelChanges}
            />
        </TabContainer>

    );
}

export default SystemGeneralTab;