import baseStyled, { ThemedStyledInterface } from 'styled-components';

import Card from '@plasma/ui.display.card/dist/card';

import { SemanticsTheme } from '../../../themes/types';

const styled = baseStyled as ThemedStyledInterface<SemanticsTheme>;

export const StyledCard = styled(Card)`
  height: 150px;
  width: 150px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }

  &:active {
    background-color: #e0e0e0;
    transform: scale(0.95);
  }

  .title {
    font-size: 14px;
  }

  .subtitle {
    font-size: 12px;
  }
`;
