import React from "react";

import Button from "@plasma/ui.general.button";

import { MainContainer } from "./FormButtons.styles";

interface FormButtonsProps {
    objectValid?: boolean;
    objectChanged?: boolean;
    handleOnSave: (itemToSave: any) => void;
    itemToSave?: any;
    handleOnCancel: (onCancelParam?: any) => void;
    onCancelParam?: any;
}

function FormButtons(props: FormButtonsProps) {
    const objectChanged = props.objectChanged !== undefined ? props.objectChanged : true;
    const objectValid = props.objectValid !== undefined ? props.objectValid : true;

    return (
        <MainContainer>
            <Button
                disabled={!objectChanged}
                title="button.cancel"
                type="secondary"
                onClick={() => props.handleOnCancel(props.onCancelParam)}
            />
            <Button
                disabled={!objectValid || !objectChanged}
                title="button.save"
                type="primary"
                onClick={() => {
                    props.handleOnSave(props.itemToSave);
                }}
            />
        </MainContainer>
    );
}

export default FormButtons;