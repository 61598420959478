import React, { useEffect } from "react";

import { Trans, useTranslation } from "react-i18next";
import { notification as notificationant } from "antd";
import { useNavigate } from "@plasma/ui.application.router";

import { ClickableItemMetadata } from "../../components/shared/ClickableItem/ClickableItem.types";
import { useStore } from "../../stores/store";

const useHomepageHook = () => {
    const navigate = useNavigate();
    const { SystemStore } = useStore();
    const { ClassStore } = useStore();
    const { t } = useTranslation();

    const generateClickableSystemItems = () => {
        const clickableSystemItems: ClickableItemMetadata[] = [];
        SystemStore.systems!.map((system) => {
            clickableSystemItems.push(
                new ClickableItemMetadata(system.id, system.name, `/systems/${system.id}`, system.description)
            );
        });
        return clickableSystemItems;
    }

    useEffect(() => {
        SystemStore.fetchSystems()
            .catch(() => {
                notificationant.error({
                    message: t("notification.fetch.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.fetch.error.description"}
                            values={{ itemType: t("systems.title") }}
                        />
                    ),
                });
            });
        ClassStore.fetchClasses()
            .catch(() => {
                notificationant.error({
                    message: t("notification.fetch.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.fetch.error.description"}
                            values={{ itemType: t("classes.title") }}
                        />
                    ),
                });
            });
    }, []);

    const handleNavigate = (path: string) => {
        navigate(path);
      };

    return { generateClickableSystemItems, handleNavigate };
};

export default useHomepageHook;