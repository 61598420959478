export class ProfileComposition {
    wholeId: number;
    partId: number;

    constructor (wholeId: number, partId: number) {
        this.wholeId = wholeId;
        this.partId = partId;
    };
}

export class ProfilePartDto {
    profileId: number;
    inherited: number; // 0 or 1

    constructor (profileId: number, inherited: number) {
        this.profileId = profileId;
        this.inherited = inherited;
    };
}

