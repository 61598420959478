import React, { Dispatch } from "react";

import Modal from "@plasma/ui.layout.modal";
import { Icon } from "@plasma/ui.general.icon";
import Button from "@plasma/ui.general.button";

import TextInput from "@plasma/ui.input.text-input";
import { Trans, useTranslation } from "react-i18next";
import useItemDeleteModalHook from "./ItemDeleteModal.hook";
import { ItemDeleteModalHeaderContainer, ModalContent } from "./ItemDeleteModal.styles";

interface Props {
    parentChosenItemId?: number;
    childrenChosenItem: any;
    itemType: string;
    deleteItemModalOpened: boolean;
    setDeleteItemModalOpened: Dispatch<boolean>;
    onDelete: (childrenItemId: number, parentItemId?: number) => void;
}

function ItemDeleteModal(props: Props) {

  const { t } = useTranslation();

  const Content = () => {
    const logic = useItemDeleteModalHook(props.childrenChosenItem);

    return (
      <ModalContent>
        <p>
          <Trans
            values={{ name: props.childrenChosenItem?.name }}
            i18nKey={"delete-modal.info-message"}
          />
        </p>
        <TextInput
          required
          onChange={(event) => {
            logic.validateInput(event.target.value);
          }}
        />
        {logic.formInputValid ? (
          <div className="warning-message">
            <Trans
              values={{ name: props.childrenChosenItem?.name }}
              i18nKey={"delete-modal.confirm-message"}
            />
          </div>
        ) : null}
        <div className="button-container">
          <Button.Cancel
            title={t("button.cancel")}
            type="secondary"
            onClick={() => props.setDeleteItemModalOpened(false)}
          />
          {logic.formInputValid ? (
            <>
              <Button
                title={t("button.delete")}
                onClick={() => props.onDelete(props.childrenChosenItem.id, props.parentChosenItemId)}
              />
            </>
          ) : (
            <>
              <Button title={t("button.delete")} disabled />
            </>
          )}
        </div>
      </ModalContent>
    );
  };
  
  return (
    <Modal
      footer={null}
      title={
        <ItemDeleteModalHeaderContainer>
          <Icon name="delete" variant="filled" style={{ color: "red" }} />
          <div className="modal-header">{t("delete-modal.title")} {props.itemType}</div>
        </ItemDeleteModalHeaderContainer>
      }
      centered={true}
      destroyOnClose={true}
      visible={props.deleteItemModalOpened}
      onCancel={() => props.setDeleteItemModalOpened(false)}
    >
      <Content />
    </Modal>
  );
}

export default ItemDeleteModal;
