import React from 'react';

import { Route } from '@plasma/ui.application.router';

const AttributeOverview = React.lazy(() => import('.'));

export const AttributeOverviewRoutes = [
  new Route({
    path: '/profiles/:profileId/attributes/:attributeId',
    element: AttributeOverview,
  }),
];