import baseStyled, { ThemedStyledInterface, } from 'styled-components';

import { SemanticsTheme } from '../../../../themes/types';

const styled = baseStyled as ThemedStyledInterface<SemanticsTheme>;

export const TabContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow: auto;
    height: 100%;

    .form {
        min-width: 250px;
        width: 50%;
        padding: 16px;
    }
`