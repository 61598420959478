import React, { useEffect, useMemo, useState } from "react";

import { notification as notificationant } from "antd";
import { Trans, useTranslation } from "react-i18next";

import { useStore } from "../../../../stores/store";
import { TransferData } from "../../../../models/types/TransferData";
import { checkObjectsAreEqual } from "../../../../utils/objectsEqualityChecker";
import services from "../../../../api/agent";
import { FeatureCompleteDto } from "../../../../models/dtos/FeatureDtos";
import { identifyItemsToAdd } from "../../../../utils/itemsToAddIdentifier";
import { identifyItemsToRemove } from "../../../../utils/itemsToRemoveIdentifier";
import { ClassAssociatedFeatureDto } from "../../../../models/dtos/class/ClassAssociatedFeatureDto";

const useClassFeaturesTabHook = () => {
    const { ClassStore } = useStore();
    const { FeatureStore } = useStore();
    const [initialTargetKeys, setInitialTargetKeys] = useState<string[]>([]);
    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [transferData, setTransferData] = useState<TransferData[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        setTransferData([]);
        setTargetKeys([]);
        if (!FeatureStore.features.length) {
            FeatureStore.fetchFeatures()
                .then(() => {
                    buildTransferData(FeatureStore.features);
                })
                .catch(() => {
                    notificationant.error({
                        message: t("notification.fetch.error.title"),
                        description: (
                            <Trans
                                i18nKey={"notification.fetch.error.description"}
                                values={{ itemType: t("features.title") }}
                            />
                        )
                    });
                });
        } else {
            buildTransferData(FeatureStore.features);
        };
        ClassStore.reloadAssociatedFeatures(ClassStore.chosenClass!.id)
            .then(() => {
                buildTargetKeys(ClassStore.associatedFeatures);
            })
            .catch(() => {
                notificationant.error({
                    message: t("notification.fetch.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.fetch.error.description"}
                            values={{ itemType: t("features.associated") }}
                        />
                    )
                });
            });
    }, []);

    const buildTargetKeys = (associatedFeatures: FeatureCompleteDto[]) => {
        const associatedFeatureIds = associatedFeatures.map((feature) => feature.id.toString());
        setInitialTargetKeys(associatedFeatureIds);
        setTargetKeys(associatedFeatureIds);
    };

    const buildTransferData = (features: FeatureCompleteDto[]) => {
        features.map((feature) => {
            setTransferData((prev) => [...prev, new TransferData(feature.id.toString(), feature.name)]);
        });
    };

    const handleOnChange = (nextTargetKeys: string[]) => {
        setTargetKeys(nextTargetKeys);
    };

    const objectChanged = useMemo(() => {
        return !checkObjectsAreEqual(initialTargetKeys ?? [], targetKeys ?? []);
    }, [initialTargetKeys, targetKeys]);

    const handleOnCancel = () => {
        setTargetKeys(initialTargetKeys);
    };

    const handleOnSave = async (selectedTargetKeys: string[]) => {
        await handleOnUpdate(selectedTargetKeys);
        await ClassStore.reloadAssociatedFeatures(ClassStore.chosenClass!.id)
            .catch(() => {
                notificationant.error({
                    message: t("notification.fetch.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.fetch.error.description"}
                            values={{ itemType: t("features.associated") }}
                        />
                    )
                });
            });
        buildTargetKeys(ClassStore.associatedFeatures);
    };

    const handleOnUpdate = async (selectedTargetKeys: string[]) => {
        const featuresToAdd = identifyItemsToAdd(initialTargetKeys, selectedTargetKeys, FeatureStore.features);
        const featuresToRemove = identifyItemsToRemove(initialTargetKeys, selectedTargetKeys);

        const addPromises = featuresToAdd.map(feature => handleOnAdd(new ClassAssociatedFeatureDto(ClassStore.chosenClass!.id, feature.id)));
        const removePromises = featuresToRemove.map(featureId => handleOnRemove(Number(featureId)));

        await Promise.all([...addPromises, ...removePromises]);
    };

    const handleOnAdd = async (data: ClassAssociatedFeatureDto) => {
        await services.classes.addFeature(ClassStore.chosenClass!.id, data)
            .then(() => {
                notificationant.success({
                    message: t("notification.create.success.title"),
                    description: (
                    <Trans
                        i18nKey={"notification.create.success.description"}
                        values={{ itemType: t("features.associated"), itemName: "" }}
                    />
                    ),
                });
            })
            .catch(() => {
                notificationant.error({
                    message: t("notification.create.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.create.error.description"}
                            values={{ itemType: t("features.associated"), itemName: "" }}
                        />
                    ),
                });
            });
    };

    const handleOnRemove = async (featureId: number) => {
        await services.classes.removeFeature(ClassStore.chosenClass!.id, featureId)
            .then(() => {
                notificationant.success({
                    message: t("notification.delete.success.title"),
                    description: (
                    <Trans
                        i18nKey={"notification.delete.success.description"}
                        values={{ itemType: t("features.associated"), itemName: "" }}
                    />
                    ),
                });
            })
            .catch(() => {
                notificationant.error({
                    message: t("notification.delete.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.delete.error.description"}
                            values={{ itemType: t("features.associated"), itemName: "" }}
                        />
                    ),
                });
            });
    };

    return {
        targetKeys,
        setTargetKeys,
        buildTransferData,
        transferData,
        handleOnChange,
        objectChanged,
        handleOnCancel,
        handleOnSave,
    };

};

export default useClassFeaturesTabHook;
