import React, { useState } from "react";

import ThemeProvider from "@plasma/ui.themes.theme-provider";
import { ThemeOverride } from "@plasma/ui.utils.types";
import { useParams } from '@plasma/ui.application.router';
import Tabs, { CustomTabPane } from "@plasma/ui.navigation.tabs";
import { Icon } from "@plasma/ui.general.icon";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";


import themes from "../../../themes";
import Breadcrumbs from "../../../components/shared/Breadcrumbs/Breadcrumbs";
import SmsSpin from "../../../components/core/SmsSpin/SmsSpin";
import { MainContainer } from "./class-overview.styles";
import useClassOverviewHook from "./class-overview.hook";
import OpenDeleteModalButton from "../../../components/shared/OpenDeleteModalButton/OpenDeleteModalButton";
import ItemDeleteModal from "../../../components/shared/ItemDeleteModal/ItemDeleteModal";
import { useStore } from "../../../stores/store";
import ClassGeneralTab from "../../../components/features/ClassOverview/ClassGeneralTab/ClassGeneralTab";
import ClassFeaturesTab from "../../../components/features/ClassOverview/ClassFeaturesTab/ClassFeaturesTab";

function ClassOverview(props: Readonly<ThemeOverride>) {
    const { size, themeMode } = props;
    const { t } = useTranslation();
    const { ClassStore } = useStore();
    const { classId } = useParams();
    const logic = useClassOverviewHook(Number(classId));
    const [deleteModalOpened, setDeleteModalOpened] = useState<boolean>(false);

    return (
        <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
            <MainContainer>
                {ClassStore.chosenClassIsLoading ? (
                    <SmsSpin />
                ) : (
                    <>
                        <Breadcrumbs
                            newCurrentPath={`/classes/${classId}`}
                            chosenClass={ClassStore.chosenClass?.name}
                            chosenClassId={Number(classId)}
                        />
                        <div className="overview">
                            <div className="header">
                                <h1><strong>{ClassStore.chosenClass?.name}</strong></h1>
                                <OpenDeleteModalButton setDeleteAssetModalOpened={setDeleteModalOpened}/>
                            </div>
                            <Tabs className="tabs" type="line" destroyInactiveTabPane={true}>
                                <Tabs.TabPane
                                    className="tab-panel"
                                    key={1}
                                    tab={
                                        <CustomTabPane
                                            icon={<Icon name="Sell" variant="filled" />}
                                            title={t("tab.general")}
                                        />
                                    }
                                >
                                    <ClassGeneralTab/>
                                </Tabs.TabPane>
                                <Tabs.TabPane
                                    className="tab-panel"
                                    key={2}
                                    tab={
                                        <CustomTabPane
                                            icon={<Icon name="StackedLineChart" variant="filled" />}
                                            title={t("tab.features")}
                                        />
                                    }
                                >
                                    <ClassFeaturesTab/>
                                </Tabs.TabPane>
                            </Tabs>
                        </div>
                        <ItemDeleteModal
                            childrenChosenItem={ClassStore.chosenClass}
                            itemType={t("classes.title.single")}
                            deleteItemModalOpened={deleteModalOpened}
                            setDeleteItemModalOpened={setDeleteModalOpened}
                            onDelete={logic.handleOnDelete}
                        />
                    </>
                )}
            </MainContainer>
        </ThemeProvider>
    );
}

export default observer(ClassOverview);