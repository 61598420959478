import React, { useEffect, useMemo, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { notification as notificationant } from "antd";
import { useNavigate } from "@plasma/ui.application.router";

import { useStore } from "../../../stores/store";
import { ProfileDto } from "../../../models/dtos/profile/ProfileDtos";
import { ProfileStatus } from "../../../models/enums/ProfileEnums";
import { SelectOptions } from "../../../models/types/SelectOptions";

const useCreateProfileHook = () => {
    const { ProfileStore } = useStore();
    const { ClassStore } = useStore();
    const [profileToCreate, setProfileToCreate] = useState<ProfileDto>();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (!ClassStore.classes.length) {
            ClassStore.fetchClasses()
                .catch(() => {
                    notificationant.error({
                        message: t("notification.fetch.error.title"),
                        description: (
                            <Trans
                                i18nKey={"notification.fetch.error.description"}
                                values={{ itemType: t("classes.title") }}
                            />
                        ),
                    });
                });
        }
    }, []);

    const handleCreateProfile = async (data: ProfileDto) => {
        data.misconfigured = false;
        data.thingSpecific = false;
        await ProfileStore.createProfile(data)
            .then(() => {
                handleNavigate("/profiles");
                notificationant.success({
                    message: t("notification.create.success.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.create.success.description"}
                            values={{ itemType: t("profiles.title.single"), itemName: data.name }}
                        />
                    ),
                });
            });
    }

    const handleNavigate = (path: string) => {
        navigate(path);
      };

    const objectValid = useMemo(() => {
        return profileToCreate?.name !== undefined && profileToCreate?.name !== "" 
            && profileToCreate?.thingTypeId !== undefined && profileToCreate?.status !== undefined;
    }, [profileToCreate]);

    const statusSelectOptions = Object.keys(ProfileStatus)
        .map((key) => (new SelectOptions(ProfileStatus[key], key)));

    const classSelectOptions = useMemo(() => {
        return ClassStore.classes.map((item) => (new SelectOptions(item.id, item.name)));
    }, [ClassStore.classes]);

    return {
        profileToCreate,
        setProfileToCreate,
        handleCreateProfile,
        objectValid,
        handleNavigate,
        statusSelectOptions,
        classSelectOptions
    };
}

export default useCreateProfileHook;