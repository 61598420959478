import React, { useEffect } from "react";

import { Trans, useTranslation } from "react-i18next";
import { notification as notificationant } from "antd";
import { useNavigate } from "@plasma/ui.application.router";

import { useStore } from "../../../stores/store";

const useRelationTypeOverviewHook = (chosenRelationTypeId: number) => {
    const navigate = useNavigate();
    const { ClassStore, RelationTypeStore } = useStore();
    const { t } = useTranslation();

    useEffect(() => {
        if (chosenRelationTypeId) {
            RelationTypeStore.fetchChosenRelationType(chosenRelationTypeId)
                .catch(() => {
                    notificationant.error({
                        message: t("notification.fetch.error.title"),
                        description: (
                            <Trans
                                i18nKey={"notification.fetch.error.description"}
                                values={{ itemType: t("relation-types.title.single") }}
                            />
                        ),
                    });
                });
        };
        if (!ClassStore.classes.length) {
            ClassStore.fetchClasses()
                .catch(() => {
                    notificationant.error({
                        message: t("notification.fetch.error.title"),
                        description: (
                            <Trans
                                i18nKey={"notification.fetch.error.description"}
                                values={{ itemType: t("classes.title") }}
                            />
                        ),
                    });
                });
        }
    }, []);

    const handleNavigate = (path: string) => {
        navigate(path);
      };

    const handleOnDelete = async (id: number) => {
        await RelationTypeStore.deleteRelationType(id)
            .then(() => {
                handleNavigate("/relation-types");
                notificationant.success({
                    message: t("notification.delete.success.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.delete.success.description"}
                            values={{ itemType: t("relation-types.title.single"), itemName: RelationTypeStore.chosenRelationType?.name }}
                        />
                    ),
                });
            })
            .catch(() => {
                handleNavigate("/relation-types");
                notificationant.error({
                    message: t("notification.delete.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.delete.error.description"}
                            values={{ itemType: t("relation-types.title.single"), itemName: RelationTypeStore.chosenRelationType?.name }}
                        />
                    ),
                });
            });
    };

    return {
        handleOnDelete,
        handleNavigate,
    };
};

export default useRelationTypeOverviewHook;