export class SystemAssociatedFeatureDto {
    systemId: number;
    featureTypeId: number;
    displayOrder?: number;

    constructor(systemId: number, featureTypeId: number, displayOrder?: number) {
        this.systemId = systemId;
        this.featureTypeId = featureTypeId;
        this.displayOrder = displayOrder;
    };
}