import { makeAutoObservable } from "mobx";

import { ClassCompleteDto, ClassDto } from "../models/dtos/class/ClassDtos";
import services from "../api/agent";
import { FeatureCompleteDto } from "../models/dtos/FeatureDtos";

export default class ClassStore {
  classes: ClassCompleteDto[] = [];
  classesAreLoading: boolean = false;

  chosenClassIsLoading: boolean = false;
  chosenClass: ClassCompleteDto | null = null;

  associatedFeatures: FeatureCompleteDto[] = [];
  associatedFeaturesAreLoading: boolean = false;

  // Classes
  fetchClasses = async () => {
    this.classesAreLoading = true;
    
    await services.classes.getAll()
    .then((response) => {
        this.classes = response.data;
        this.classesAreLoading = false;
    });
  };

  createClass = async (classDto: ClassDto) => {
    await services.classes.create(classDto).then((response) => {
      this.classes.push(response.data);
    });
  };

  deleteClass = async (id: number) => {
    await services.classes.delete(id).then(() => {
      const indexToRemove = this.classes.findIndex((c) => c.id === id);
      this.classes.splice(indexToRemove, 1);
    });
  };

  reloadClasses = async () => {
    this.classes = [];
    await this.fetchClasses();
  };

  // Chosen class
  fetchChosenClass = async (id: number) => {
    this.chosenClassIsLoading = true;
    await services.classes.getById(id)
        .then((respone) => {this.chosenClass=respone.data; this.chosenClassIsLoading=false;});
  }

  updateClass = async (classDto: ClassCompleteDto) => {
    const { id, ...updatedClass } = classDto;
    await services.classes.update(id, updatedClass).then((respone) => {
      const responseData = respone.data;
      this.chosenClass = responseData;
      this.classes.map((c) => {
        if (c.id === responseData.id) {
          Object.assign(c, responseData);
        }
      });    
    });
  };

  reloadChosenClass = async (id: number) => {
    this.chosenClass = null;
    await this.fetchChosenClass(id);
  }

  // Associated class features
  fetchAssociatedFeatures = async (classId: number) => {
    this.associatedFeaturesAreLoading = true;
    await services.classes.getFeatures(classId)
        .then((response) => {this.associatedFeatures=response.data; this.associatedFeaturesAreLoading=false;});
  }

  reloadAssociatedFeatures = async (classId: number) => {
    this.associatedFeatures = [];
    await this.fetchAssociatedFeatures(classId);
  }


  constructor() {
    makeAutoObservable(this);
  }
}