import React, { useEffect, useMemo, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { notification as notificationant } from "antd";

import { useStore } from "../../../../stores/store";
import { TransferData } from "../../../../models/types/TransferData";
import { checkObjectsAreEqual } from "../../../../utils/objectsEqualityChecker";
import services from "../../../../api/agent";
import { ProfileCompleteDto } from "../../../../models/dtos/profile/ProfileDtos";
import { ProfileComposition, ProfilePartDto } from "../../../../models/dtos/profile/ProfileComposition";

const useProfileCompositionTabHook = () => {
    const { ProfileStore } = useStore();
    const [initialTargetKeys, setInitialTargetKeys] = useState<string[]>([]);
    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [transferData, setTransferData] = useState<TransferData[]>([]);
    const { t } = useTranslation();
    const [inheritanceToBeSet, setInheritanceToBeSet] = useState<boolean>(true);

    useEffect(() => {
        setTransferData([]);
        setTargetKeys([]);
        setInheritanceToBeSet(true);
        if (!ProfileStore.profiles.length) {
            ProfileStore.fetchProfiles()
                .then(() => {
                    buildTransferData(ProfileStore.profiles);
                })
                .catch(() => {
                    notificationant.error({
                        message: t("notification.fetch.error.title"),
                        description: (
                            <Trans
                                i18nKey={"notification.fetch.error.description"}
                                values={{ itemType: t("profiles.title") }}
                            />
                        )
                    });
                });
        } else {
            buildTransferData(ProfileStore.profiles);
        };
        ProfileStore.reloadAssociatedProfiles(ProfileStore.chosenProfile!.id)
            .then(() => {
                buildTargetKeys(ProfileStore.associatedProfiles);
            })
            .catch(() => {
                notificationant.error({
                    message: t("notification.fetch.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.fetch.error.description"}
                            values={{ itemType: t("profiles.associated") }}
                        />
                    )
                });
            });
    }, []);

    useMemo(() => {
        if (targetKeys.length && transferData.length && inheritanceToBeSet) {
            transferData.map((dataItem) => {
                const keySelected = targetKeys.includes(dataItem.key);
                const associatedProfile = ProfileStore.associatedProfiles.find((associatedProfile) => associatedProfile.profileId === Number(dataItem.key));
                const keyInherited = associatedProfile?.inherited === 1;
                dataItem.disabled = keySelected && keyInherited;
            });
            setInheritanceToBeSet(false);
        }
        
    }, [targetKeys, transferData]);

    const buildTargetKeys = (associatedProfiles: ProfilePartDto[]) => {
        const associatedProfileIds = associatedProfiles.map((profile) => profile.profileId.toString());
        setInitialTargetKeys(associatedProfileIds);
        setTargetKeys(associatedProfileIds);
    };

    const buildTransferData = (profiles: ProfileCompleteDto[]) => {
        const sufficientProfiles = profiles.filter((profile) => profile.id !== ProfileStore.chosenProfile!.id);
        sufficientProfiles.map((profile) => {
            setTransferData((prev) => [...prev, new TransferData(profile.id.toString(), profile.name, profile.description)]);
        });
    };

    const removeKeysFromTargetKeys = (newSelectedTargetKeys: string[]) => {
        const keysToRemove = targetKeys.filter((t) => !newSelectedTargetKeys.includes(t));
        const promises = keysToRemove.map(async (key) => {
            await services.profiles.getCompositions(Number(key))
                .then((response) => {
                    const childrenToRemoveProfileIds = response.data.map((profile) => profile.profileId.toString());
                    childrenToRemoveProfileIds.map((childId) => {
                        const indexToRemove = newSelectedTargetKeys.indexOf(childId);
                        newSelectedTargetKeys.splice(indexToRemove, 1);
                        const dataItem = transferData.find((dataItem) => dataItem.key === childId);
                        if (dataItem) {
                            dataItem.disabled = false;
                        }
                    });
                });         
        });
        return promises;
    };
    
    const addKeysToTargetKeys = (newSelectedTargetKeys: string[]) => {
        const keysToAdd = newSelectedTargetKeys.filter((t) => !targetKeys.includes(t));
        const promises = keysToAdd.map(async (key) => {
            await services.profiles.getCompositions(Number(key))
                .then((response) => {
                    const childrenToAddProfileIds = response.data.map((profile) => profile.profileId.toString());
                    childrenToAddProfileIds.map((childId) => {
                        if (!newSelectedTargetKeys.includes(childId)) {
                            newSelectedTargetKeys.push(childId);
                        }
                        const dataItem = transferData.find((dataItem) => dataItem.key === childId);
                        if (dataItem) {
                            dataItem.disabled = true;
                        }
                    });
                });
        });
        return promises;
    };

    const handleOnChange = async (newSelectedTargetKeys: string[]) => {
        setInheritanceToBeSet(false);
        const removePromises = removeKeysFromTargetKeys(newSelectedTargetKeys);
        const addPromises = addKeysToTargetKeys(newSelectedTargetKeys);

        await Promise.all([...removePromises, ...addPromises]).then(() => {
            setTargetKeys(newSelectedTargetKeys);
        });
    };

    const objectChanged = useMemo(() => {
        return !checkObjectsAreEqual(initialTargetKeys ?? [], targetKeys ?? []);
    }, [initialTargetKeys, targetKeys]);

    const handleOnCancel = () => {
        setInheritanceToBeSet(true);
        setTargetKeys(initialTargetKeys);
    };

    const handleOnSave = async (selectedTargetKeys: string[]) => {
        await handleOnUpdate(selectedTargetKeys);
        await ProfileStore.reloadAssociatedProfiles(ProfileStore.chosenProfile!.id)
            .catch(() => {
                notificationant.error({
                    message: t("notification.fetch.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.fetch.error.description"}
                            values={{ itemType: t("profiles.associated") }}
                        />
                    )
                });
            });
        buildTargetKeys(ProfileStore.associatedProfiles);
    };

    const handleOnUpdate = async (selectedTargetKeys: string[]) => {
        const associatations: ProfileComposition[] = [];
        selectedTargetKeys.forEach(selectedProfileId => {
            associatations.push(new ProfileComposition(ProfileStore.chosenProfile!.id, Number(selectedProfileId)));
        });
        await services.profiles.addCompositions(ProfileStore.chosenProfile!.id, associatations)
            .then(() => {
                notificationant.success({
                    message: t("notification.update.success.title"),
                    description: (
                    <Trans
                        i18nKey={"notification.update.success.description"}
                        values={{ itemType: t("profiles.associated"), itemName: "" }}
                    />
                    ),
                });
            })
            .catch(() => {
                notificationant.error({
                    message: t("notification.update.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.update.error.description"}
                            values={{ itemType: t("profiles.associated"), itemName: "" }}
                        />
                    ),
                });
            });
    };

    return {
        targetKeys,
        setTargetKeys,
        buildTransferData,
        transferData,
        handleOnChange,
        objectChanged,
        handleOnCancel,
        handleOnSave
    };

};

export default useProfileCompositionTabHook;
