import React from "react";

import ThemeProvider from "@plasma/ui.themes.theme-provider";
import { ThemeOverride } from "@plasma/ui.utils.types";
import { observer } from "mobx-react-lite";
import Button from "@plasma/ui.general.button";
import { useTranslation } from "react-i18next";


import themes from "../../themes";
import { MainContainer } from "./classes.styles";
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";
import ClickableItemCard from "../../components/shared/ClickableItem/ClickableItem";
import SmsSpin from "../../components/core/SmsSpin/SmsSpin";
import { useStore } from "../../stores/store";
import useClassesHook from "./classes.hook";

function Classes(props: Readonly<ThemeOverride>) {
    const { size, themeMode } = props;
    const { ClassStore } = useStore();
    const { t } = useTranslation();
    const logic = useClassesHook();

    return (
        <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
            <MainContainer>
                <Breadcrumbs newCurrentPath="/classes" />
                <div className="classes">
                    <h1><strong>{t("classes.title")}</strong></h1>
                    <Button 
                        className="create-class-button" 
                        title={t("button.create.class")}
                        type="primary" 
                        onClick={() => logic.handleNavigate("/classes/create")} 
                    />
                    {ClassStore.classesAreLoading ? <SmsSpin/> : 
                        <div className="item-container">
                            {logic.generateClickableClassItems().map((item) => (
                                <ClickableItemCard
                                    key={item.id}
                                    item={item}
                                    iconName="Extension"
                                    onSelect={() => logic.handleNavigate(item.path)}
                                />
                            ))}
                        </div>
                    }
                </div>
            </MainContainer>
        </ThemeProvider>
    );
}

export default observer(Classes);