import React, { useMemo, useState } from "react";

import { notification as notificationant } from "antd";
import { Trans, useTranslation } from "react-i18next";

import { ClassCompleteDto } from "../../../../models/dtos/class/ClassDtos"
import { useStore } from "../../../../stores/store";
import { checkObjectsAreEqual } from "../../../../utils/objectsEqualityChecker";

const useClassGeneralHook = () => {
    const { ClassStore } = useStore();
    const [updatedClass, setUpdatedClass] = useState<ClassCompleteDto>(ClassStore.chosenClass!);
    const [formKey, setFormKey] = useState(1);
    const { t } = useTranslation();

    const onUpdateClass = async (classToUpdate: ClassCompleteDto) => {
        ClassStore.updateClass(classToUpdate)
            .then(() => {
                setUpdatedClass(ClassStore.chosenClass!);
                notificationant.success({
                    message: t("notification.update.success.title"),
                    description: (
                      <Trans
                        i18nKey={"notification.update.success.description"}
                        values={{ itemType: t("classes.title.single"), itemName: classToUpdate.name }}
                      />
                    ),
                });
            })
            .catch(() => {
                notificationant.error({
                    message: t("notification.update.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.update.error.description"}
                            values={{ itemType: t("classes.title.single"), itemName: classToUpdate.name }}
                        />
                    ),
                });
            });
    };

    const onCancelChanges = () => {
        setFormKey(formKey + 1);
        setUpdatedClass(ClassStore.chosenClass!);
    };

    const objectValid = useMemo(() => {
        return updatedClass?.name !== undefined && updatedClass?.name !== "" 
            && updatedClass?.code !== undefined && updatedClass?.code !== "";
    }, [updatedClass]);

    const objectChanged = useMemo(() => {
        return !checkObjectsAreEqual(ClassStore.chosenClass ?? "", updatedClass ?? "");
      }, [ClassStore.chosenClass, updatedClass]);

    return {
        objectValid,
        updatedClass,
        setUpdatedClass,
        formKey,
        onCancelChanges,
        objectChanged,
        onUpdateClass
    };
}

export default useClassGeneralHook;