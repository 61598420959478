import React, { useEffect } from "react";

import { Trans, useTranslation } from "react-i18next";
import { notification as notificationant } from "antd";
import { useNavigate } from "@plasma/ui.application.router";

import { useStore } from "../../../stores/store";

const useSystemOverviewHook = (chosenSystemId: number) => {
    const navigate = useNavigate();
    const { SystemStore } = useStore();
    const { t } = useTranslation();

    useEffect(() => {
        if (chosenSystemId) {
            SystemStore.fetchChosenSystem(chosenSystemId)
                .catch(() => {
                    notificationant.error({
                        message: t("notification.fetch.error.title"),
                        description: (
                            <Trans
                                i18nKey={"notification.fetch.error.description"}
                                values={{ itemType: t("systems.title.single") }}
                            />
                        ),
                    });
                });
        };
    }, []);

    const handleNavigate = (path: string) => {
        navigate(path);
      };

    const handleOnDelete = async (id: number) => {
        await SystemStore.deleteSystem(id)
            .then(() => {
                handleNavigate("/");
                notificationant.success({
                    message: t("notification.delete.success.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.delete.success.description"}
                            values={{ itemType: t("systems.title.single"), itemName: SystemStore.chosenSystem?.name }}
                        />
                    ),
                });
            })
            .catch(() => {
                handleNavigate("/");
                notificationant.error({
                    message: t("notification.delete.error.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.delete.error.description"}
                            values={{ itemType: t("systems.title.single"), itemName: SystemStore.chosenSystem?.name }}
                        />
                    ),
                });
            });
    };

    return {
        handleOnDelete,
        handleNavigate,
    };
};

export default useSystemOverviewHook;