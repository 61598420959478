import React from "react";

import ThemeProvider from "@plasma/ui.themes.theme-provider";
import { ThemeOverride } from "@plasma/ui.utils.types";
import Form from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import TextInput from "@plasma/ui.input.text-input";
import TextArea from "@plasma/ui.input.text-area";
import Select from "@plasma/ui.input.select";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import themes from "../../../themes";
import Breadcrumbs from "../../../components/shared/Breadcrumbs/Breadcrumbs";
import { MainContainer } from "./create-profile.styles";
import useCreateProfileHook from "./create-profile.hook";
import FormButtons from "../../../components/shared/FormButtons/FormButtons";
import { ProfileDto } from "../../../models/dtos/profile/ProfileDtos";
import { useStore } from "../../../stores/store";
import SmsSpin from "../../../components/core/SmsSpin/SmsSpin";

function CreateProfile(props: Readonly<ThemeOverride>) {
    const { size, themeMode } = props;
    const { t } = useTranslation();
    const logic = useCreateProfileHook();
    const { ClassStore } = useStore();

    return (
        <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
            <MainContainer>
                <Breadcrumbs newCurrentPath="/profiles/create" />
                <h1 className="header"><strong>{t("profiles.create.title")}</strong></h1>
                {ClassStore.classesAreLoading ? <SmsSpin/> : 
                    <>
                        <Form 
                            className="form"
                            onChange={(data: Partial<ProfileDto>) => logic.setProfileToCreate(data as ProfileDto)}
                        >
                            <FormField required label={t("form.name")} name="name">
                                <TextInput required/>
                            </FormField>
                            <FormField label={t("form.description")} name="description">
                                <TextArea rows={5}/>
                            </FormField>
                            <FormField required label={t("form.thing-type")} name="thingTypeId">
                                <Select options={logic.classSelectOptions}/>
                            </FormField>
                            <FormField required label={t("form.status")} name="status">
                                <Select options={logic.statusSelectOptions}/>
                            </FormField>
                        </Form>
                        <FormButtons 
                            objectValid={logic.objectValid} 
                            handleOnSave={logic.handleCreateProfile}
                            itemToSave={logic.profileToCreate}
                            handleOnCancel={logic.handleNavigate}
                            onCancelParam={"/profiles"}
                        />
                    </>
                }
            </MainContainer>
        </ThemeProvider>
    );
}

export default observer(CreateProfile);