import React from "react";

import ThemeProvider from "@plasma/ui.themes.theme-provider";
import { ThemeOverride } from "@plasma/ui.utils.types";
import { observer } from "mobx-react-lite";
import Button from "@plasma/ui.general.button";
import { useTranslation } from "react-i18next";

import themes from "../../themes";
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";
import ClickableItemCard from "../../components/shared/ClickableItem/ClickableItem";
import SmsSpin from "../../components/core/SmsSpin/SmsSpin";
import { useStore } from "../../stores/store";
import useRelationTypesHook from "./relation-types.hook";
import { MainContainer } from "./relation-types.styles";

function RelationTypes(props: Readonly<ThemeOverride>) {
    const { size, themeMode } = props;
    const { RelationTypeStore } = useStore();
    const { t } = useTranslation();
    const logic = useRelationTypesHook();

    return (
        <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
            <MainContainer>
                <Breadcrumbs newCurrentPath="/relation-types" />
                <div className="relation-types">
                    <h1><strong>{t("relation-types.title")}</strong></h1>
                    <Button 
                        className="create-relation-type-button" 
                        title={t("button.create.relation-type")}
                        type="primary" 
                        onClick={() => logic.handleNavigate("/relation-types/create")} 
                    />
                    {RelationTypeStore.releationTypesAreLoading ? <SmsSpin/> : 
                        <div className="item-container">
                            {logic.generateClickableRelationTypeItems().map((item) => (
                                <ClickableItemCard
                                    key={item.id}
                                    item={item}
                                    iconName="Extension"
                                    onSelect={() => logic.handleNavigate(item.path)}
                                />
                            ))}
                        </div>
                    }
                </div>
            </MainContainer>
        </ThemeProvider>
    );
}

export default observer(RelationTypes);