import React from "react";

import ThemeProvider from "@plasma/ui.themes.theme-provider";
import { ThemeOverride } from "@plasma/ui.utils.types";
import { observer } from "mobx-react-lite";
import Button from "@plasma/ui.general.button";
import Select from "@plasma/ui.input.select";
import { useTranslation } from "react-i18next";


import themes from "../../themes";
import { MainContainer } from "./profiles.styles";
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";
import ClickableItemCard from "../../components/shared/ClickableItem/ClickableItem";
import SmsSpin from "../../components/core/SmsSpin/SmsSpin";
import { useStore } from "../../stores/store";
import useProfilesHook from "./profiles.hook";

function Profiles(props: Readonly<ThemeOverride>) {
    const { size, themeMode } = props;
    const { ProfileStore } = useStore();
    const { ClassStore } = useStore();
    const { t } = useTranslation();
    const logic = useProfilesHook();

    return (
        <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
            <MainContainer>
                <Breadcrumbs newCurrentPath="/profiles" />
                <div className="profiles">
                    <h1><strong>{t("profiles.title")}</strong></h1>
                    {ProfileStore.profilesAreLoading || ClassStore.classesAreLoading ? <SmsSpin/> :
                        <>
                            <div className="toolbar">
                                <Select
                                    defaultValue={logic.selectOptions[0]}
                                    className="select-class"
                                    options={logic.selectOptions}
                                    onChange={(value) => {
                                        logic.handleSelectOnChange(value);
                                    }}
                                />
                                <Button
                                    title={t("button.create.profile")}
                                    type="primary" 
                                    onClick={() => logic.handleNavigate("/profiles/create")} 
                                />
                            </div>
                            <div className="item-container">
                                {logic.clickableProfileItems.map((item) => (
                                    <ClickableItemCard
                                        key={item.id}
                                        item={item}
                                        iconName="Package"
                                        onSelect={() => logic.handleNavigate(item.path)}
                                    />
                                ))}
                            </div>
                        </>
        }
                </div>
            </MainContainer>
        </ThemeProvider>
    );
}

export default observer(Profiles);