import { Route, RouteObject } from '@plasma/ui.application.router';

import { HomepageRoutes } from './pages/homepage';
import { CreateSystemRoutes } from './pages/homepage/create-system/create-system.routes';
import { ClassesRoutes } from './pages/classes';
import { CreateClassRoutes } from './pages/classes/create-class';
import { SystemOverviewRoutes } from './pages/systems/system-overview';
import { ClassOverviewRoutes } from './pages/classes/class-overview';
import { ProfilesRoutes } from './pages/profiles';
import { CreateProfileRoutes } from './pages/profiles/create-profile';
import { ProfileOverviewRoutes } from './pages/profiles/profile-overview';
import { RelationTypeRoutes } from './pages/relation-types';
import { CreateRelationTypeRoutes } from './pages/relation-types/create-relation-type';
import { RelationTypeOverviewRoutes } from './pages/relation-types/relation-type-overview';
import { CreateAttributeRoutes } from './pages/profiles/profile-overview/create-attribute';
import { AttributeOverviewRoutes } from './pages/profiles/profile-overview/attribute-overview';
import { AboutRoutes } from './pages/about';

export const routes: RouteObject[] = [
  new Route({
    path: '/',
    children: [...HomepageRoutes, ...CreateSystemRoutes], 
  }),
  new Route({
    path: '/:systemId',
    children: [...SystemOverviewRoutes]
  }),
  new Route({
    path: '/classes',
    children: [...ClassesRoutes, ...CreateClassRoutes],
  }),
  new Route({
    path: '/classes/:classId',
    children: [...ClassOverviewRoutes],
  }),
  new Route({
    path: '/profiles',
    children: [...ProfilesRoutes, ...CreateProfileRoutes],
  }),
  new Route({
    path: '/profiles/:profileId',
    children: [...ProfileOverviewRoutes, ...CreateAttributeRoutes, ...AttributeOverviewRoutes],
  }),
  new Route({
    path: '/relation-types',
    children: [...RelationTypeRoutes, ...CreateRelationTypeRoutes],
  }),
  new Route({
    path: '/relation-types/:relationTypeId',
    children: [...RelationTypeOverviewRoutes],
  }),
  new Route({
    path: '/about',
    children: [...AboutRoutes]
  })
];
