import React from 'react';

import { Route } from '@plasma/ui.application.router';

const CreateProfile = React.lazy(() => import('.'));

export const CreateProfileRoutes = [
  new Route({
    path: '/profiles/create',
    element: CreateProfile,
  }),
];