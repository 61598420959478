import React from "react";

import Form from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import TextInput from "@plasma/ui.input.text-input";
import TextArea from "@plasma/ui.input.text-area";
import Select from "@plasma/ui.input.select";
import Switch from "@plasma/ui.input.switch";

import { useTranslation } from "react-i18next";
import FormButtons from "../../../../components/shared/FormButtons/FormButtons";
import { useStore } from "../../../../stores/store";
import useRelationTypeGeneralHook from "./RelationTypeGeneralTab.hook";
import { TabContainer } from "./RelationTypeGeneralTab.styles";
import { RelationTypeCompleteDto } from "../../../../models/dtos/RelationTypeDtos";

function RelationTypeGeneralTab() {
    const { t } = useTranslation();
    const logic = useRelationTypeGeneralHook();
    const { RelationTypeStore } = useStore();

    return (
        <TabContainer>
            <>
                <Form 
                    className="form"
                    onChange={(data: Partial<RelationTypeCompleteDto>) => logic.setUpdatedRelationType(data as RelationTypeCompleteDto)}
                    key={logic.formKey}
                    initialValues={RelationTypeStore.chosenRelationType!}
                >
                    <FormField required label={t("form.name")} name="name">
                        <TextInput required/>
                    </FormField>
                    <div className="from-to-relation">
                        <FormField className="from-to-selections" required label={t("form.from-thing-type")} name="fromTypeId">
                            <Select options={logic.classSelectOptions}/>
                        </FormField>
                        <FormField className="from-to-selections" required label={t("form.cardinality-type")} name="cardinality">
                            <Select options={logic.cardinalitySelectOptions}/>
                        </FormField>
                        <FormField className="from-to-selections" required label={t("form.to-thing-type")} name="toTypeId">
                            <Select options={logic.classSelectOptions}/>
                        </FormField>
                    </div>
                    <FormField label={t("form.description")} name="description">
                        <TextArea rows={5}/>
                    </FormField>
                    <FormField required label={t("form.allow-cycles")} name="isAcyclic">
                        <Switch defaultChecked={RelationTypeStore.chosenRelationType?.isAcyclic}/>
                    </FormField>
                </Form>
                <FormButtons 
                    objectValid={logic.objectValid}
                    objectChanged={logic.objectChanged}
                    handleOnSave={logic.onUpdateRelationType}
                    itemToSave={logic.updatedRelationType}
                    handleOnCancel={logic.onCancelChanges}
                />
            </>
        </TabContainer>
    );
}

export default RelationTypeGeneralTab;